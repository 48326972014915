import BackgroundImage from 'assets/images/96.jpg';
import './style.scss';
import { ProductAdvice } from 'shared/elements/ProductAdvice';

export const LargePhotoSection = () => {
  return (
    <div className="LargePhotoSectionContainer" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <ProductAdvice />
    </div>
  );
};
