// components/cards/ActiveArticleCard.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import activesCard from '../../../assets/images/activesCard.png';

interface ActiveArticleCardProps {
    active: any;
    className?: string;
}

export const ActiveArticleCard: React.FC<ActiveArticleCardProps> = ({ active, className = '' }) => (
    <Link to={`/feed/actives/${active.id}`} className={`actives-list__article-item ${className}`}>
        <div
            className="actives-list__article-image"
            style={{ backgroundImage: `url(${activesCard})` }}
        >
            <div className="actives-list__article-info">
                <h3 className="actives-list__article-title">{active.properties.Name.title[0]?.plain_text.toUpperCase()}</h3>
                <h3 className="actives-list__article-subtitle">Organic composant</h3>
            </div>
        </div>
    </Link>
);
