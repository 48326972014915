import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ErrorPage.module.scss';

interface ErrorPageProps {
    message: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = countdown > 0 ? setTimeout(() => {
            setCountdown(countdown - 1);
        }, 1000) : navigate(-1);

        return () => clearTimeout(timer as NodeJS.Timeout);
    }, [countdown, navigate]);

    return (
        !message ? (
            <div className={styles.errorPageContainer}>
                <h1>Error</h1>
                <p>An unexpected error occurred. Please try again later.</p>
                <p>You will be redirected to the previous page in {countdown} seconds.</p>
            </div>
        ) : (
            <div className={styles.errorPageContainer}>
                <div className={styles.errorContent}>
                    <h1>Oops!</h1>
                    <p>{message}</p>
                    <p>You will be redirected to the previous page in {countdown} seconds.</p>
                </div>
            </div>
        )
    );
}

export default ErrorPage;