import React, { useState, useEffect } from 'react';
import './style.scss';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import { Link, useLocation } from 'react-router-dom';

export const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('MY SKIN RESUME');
    const [user] = useAtom(userAtom);
    const location = useLocation();

    // Update selected menu item based on the current route
    useEffect(() => {
        const path = location.pathname;

        if (path.includes('favorites')) {
            setSelectedMenuItem('FAVORITES');
        } else if (path.includes('saved-articles')) {
            setSelectedMenuItem('SAVED ARTICLES');
        } else if (path.includes('my-orders')) {
            setSelectedMenuItem('MY ORDERS');
        } else if (path.includes('settings')) {
            setSelectedMenuItem('SETTINGS');
        } else {
            setSelectedMenuItem('MY SKIN RESUME');
        }
    }, [location.pathname]);

    const handleMenuClick = (item: string) => {
        setSelectedMenuItem(item);
    };

    return (
        <div className="layout">
            <div className="user-info">
                <h3 >{user?.name || 'Guest'}</h3>
                <h3>{user?.lastName || ''}</h3>
            </div>

            <div className="color-overlay">
                <div className="menu">
                    <ul>
                        <li onClick={() => handleMenuClick('MY SKIN RESUME')} className={selectedMenuItem === 'MY SKIN RESUME' ? 'selected' : ''}>
                            <Link to="/profile/skin-resume">MY SKIN RESUME</Link>
                        </li>

                        { /*    UNCOMMENT THIS WHEN YOU WANT TO MAKE THOSE BTNS ACTIVE + CHECK STYLE FILE COMMENT! */}

                        {/* <li onClick={() => handleMenuClick('FAVORITES')} className={selectedMenuItem === 'FAVORITES' ? 'selected' : ''}>
                            <Link to="/profile/favorites">FAVORITES</Link>
                        </li>
                        <li onClick={() => handleMenuClick('SAVED ARTICLES')} className={selectedMenuItem === 'SAVED ARTICLES' ? 'selected' : ''}>
                            <Link to="/profile/saved-articles">SAVED ARTICLES</Link>
                        </li>
                        <li onClick={() => handleMenuClick('MY ORDERS')} className={selectedMenuItem === 'MY ORDERS' ? 'selected' : ''}>
                            <Link to="/profile/my-orders">MY ORDERS</Link>
                        </li> */}

                        { /* START OF SECTION TO REMOVE - WHEN YOU WANT TO MAKE THOSE BTNS ACTIVE + CHECK STYLE FILE COMMENT! */}

                        <li className={selectedMenuItem === 'FAVORITES' ? 'selected' : ''}>
                            <span>FAVORITES</span>
                        </li>
                        <li className={selectedMenuItem === 'SAVED ARTICLES' ? 'selected' : ''}>
                            <span>SAVED ARTICLES</span>
                        </li>
                        <li className={selectedMenuItem === 'MY ORDERS' ? 'selected' : ''}>
                            <span>MY ORDERS</span>
                        </li>

                        { /* END OF SECTION TO REMOVE */}

                        <li onClick={() => handleMenuClick('SETTINGS')} className={selectedMenuItem === 'SETTINGS' ? 'selected' : ''}>
                            <Link to="/profile/settings">SETTINGS</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="layout-content">
                <HomepageNavbar />
                {children}
            </div>
        </div>
    );
};
