import { useQuery } from '@tanstack/react-query';
import api from '../service/API/apiConfig';
import { NotionArticle, NotionDossier, NotionActives } from './useNotionArticles.types';

// const fetchArticles = async (): Promise<NotionArticle[]> => {
//   const response = await api.get('/notion/articles');
//   return response.data;
// };

const fetchDossiers = async (): Promise<NotionDossier[]> => {
  const response = await api.get('/notion/dossiers');
  return response.data;
};

const fetchArticles = async (): Promise<NotionArticle[]> => {
  const [articlesResponse, dossiers] = await Promise.all([
    api.get('/notion/articles'),
    fetchDossiers(),
  ]);

  const articles = articlesResponse.data;

  const articlesWithDossierNames = articles.map((article: NotionArticle) => {
    const dossierNames = article.properties.Dossiers.relation.map(dossierRef => {
      const dossier = dossiers.find(d => d.id === dossierRef.id);
      return dossier ? dossier.properties.Name.title[0].plain_text : null;
    }).filter(Boolean);

    return {
      ...article,
      dossierNames,
    };
  });

  return articlesWithDossierNames;
};

const fetchActives = async (): Promise<NotionActives[]> => {
  const response = await api.get('/notion/actives');
  return response.data;
};

export const useNotionActives = () => {
  return useQuery<NotionActives[], Error>({
    queryKey: ['notionActives'],
    queryFn: fetchActives,
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });
};

export const useNotionArticles = () => {
  return useQuery<NotionArticle[], Error>({
    queryKey: ['notionArticles'],
    queryFn: fetchArticles,
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });
};

export const useNotionDossiers = () => {
  return useQuery<NotionDossier[], Error>({
    queryKey: ['notionDossiers'],
    queryFn: fetchDossiers,
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });
};
