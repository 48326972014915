import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import { InviteFriends } from '../InviteFriends/InviteFriend';
import { StartDiagnosis } from '../StartDiagnosis/StartDiagnosis';
import './style.scss';

export const SkinData: React.FC = () => {
    const [user] = useAtom(userAtom);
    const navigate = useNavigate();

    const redoDiagnosis = () => {
        navigate('/spf-form');
    };

    return (
        <div className='skin-container'>
            <div className='skin-data-container'>
                {user?.SPF?.lastUpdated === null ? (
                    <StartDiagnosis />
                ) : (
                    <>
                        <div className='skin-header-container'>
                            <h3 className='skin-header'>My skin resume</h3>
                            <Link to='/spf-form' className='header-edit-button'>EDIT</Link>
                        </div>
                        <hr className='skin-underline' />

                        <div className='skin-info-box'>
                            <div className='skin-info-box-content'>
                                <span className='skin-info-box-title'>Skin type</span>
                                <p className='skin-info-box-data'>{user?.SPF?.skinType || "Not specified"}</p>
                            </div>
                            {/* <Link to=''>EDIT</Link> */}
                        </div>

                        <div className='skin-info-box'>
                            <div className='skin-info-box-content'>
                                <span className='skin-info-box-title'>Concerns</span>
                                <p className='skin-info-box-data'>
                                    {user?.SPF?.concerns ? user.SPF.concerns.join(', ') : "Not specified"}
                                </p>
                            </div>
                            {/* <Link to=''>EDIT</Link> */}
                        </div>

                        { /* UNCOMMENT WHEN YOU WANT TO ACTIVE THE SKIN TYPE FUNCTIONALLITY */}

                        {/* <div className='skin-info-box'>
                            <div className='skin-info-box-content'>
                                <span className='skin-info-box-title'>Routine type</span>
                                <p className='skin-info-box-data'>{"Not specified"}</p>
                            </div>
                            <Link to=''>EDIT</Link>
                        </div> */}

                        <div className='skin-info-box'>
                            <div className='skin-info-box-content'>
                                <span className='skin-info-box-title'>Personal attention to risks</span>
                                <div className='skin-info-box-data'>
                                    {user?.SPF?.risks ? user.SPF.risks.map((risk, index) => (
                                        <span key={index} className='risk-item'>{risk}</span>
                                    )) : "Not specified"}
                                </div>
                            </div>
                            {/* <Link to=''>EDIT</Link> */}
                        </div>

                        <div className="button-container">
                            <button className="redo-button" onClick={redoDiagnosis}>REDO MY DIAGNOSIS</button>
                        </div>
                    </>
                )}

                <InviteFriends />
                <div className="spacer" />
            </div>
        </div>
    );
};
