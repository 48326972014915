import React from 'react';
import './style.scss';

interface ScrollNumberIndicatorProps {
  count?: number;
  fontSize?: number;
  color?: 'white' | 'black';
  activeNumber?: number;
  direction?: 'horizontal' | 'vertical';
}

export const ScrollNumberIndicator: React.FC<ScrollNumberIndicatorProps> = ({
  count = 4,
  fontSize = 12,
  color = 'white',
  activeNumber = 1,
  direction = 'horizontal',
}) => {
  return (
    <div className={`scrollIndicators ${direction}`}>
      {Array.from({ length: count }).map((_, index) => (
        <p
          className='scrollNumberIndicator'
          key={index}
          style={{
            fontSize: `${fontSize}px`,
            color: color === 'white' ? '#fff' : '#000',
            filter: index + 1 === activeNumber ? 'none' : 'blur(0.7px)',
          }}
        >
          0{index + 1}
        </p>
      ))}
    </div>
  );
};
