import React from 'react';
import './style.scss';
import infoImg from '../../../../../assets/images/infoCircle3.png';

export const CautionBox: React.FC = () => {
    return (
        <div className="caution-box">
            <div className="image-section">
                <img src={infoImg} alt="Caution" className="caution-image" />
            </div>
            <div className="text-section">
                <p className="caution-text">We are not responsible for the misuse or adverse effects of the products used. Each routine is based on your skin at every stage.</p>
            </div>
        </div>
    );
};
