import React, { useState, useEffect } from 'react';
import { Layout } from '../../../layout/layout';
import userService from '../../../../../service/UserService';
import { userAtom } from '../../../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { DeliveryService } from '../../../../../types/TInterfaces';
import deliveryApi from '../../../../../service/API/deliveryApi';
import { IAddress } from '../../../../../types/TInterfaces';
import './style.scss';

export const AddressForm: React.FC = () => {
    const { addressId } = useParams<{ addressId?: string }>();
    const [user, setUser] = useAtom(userAtom);
    const [address, setAddress] = useState<Record<string, any>>({
        addressName: '',
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        city: '',
        street: '',
        streetNumber: '',
        postalCode: '',
        addressComplement: '',
        asDefault: false
    });
    const [deliveryCountries, setDeliveryCountries] = useState<Pick<DeliveryService, 'country'>[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getDeliveryCountries = async () => {
            const { uniqueCountries } = await deliveryApi.fetchDeliveryServices();
            setDeliveryCountries(uniqueCountries);
        };

        getDeliveryCountries();
    }, []);

    useEffect(() => {
        if (addressId && user) {
            const addressToEdit = user.addresses.find((addr) => addr._id === addressId);
            if (addressToEdit) {
                const isFirstAddress = user.addresses.indexOf(addressToEdit) === 0;

                setAddress({
                    ...addressToEdit,
                    phone: addressToEdit.phoneNumber?.number,
                    asDefault: isFirstAddress ? true : addressToEdit.asDefault
                });
            }
        }
    }, [addressId, user]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const isCheckbox = type === 'checkbox';
        setAddress((prev) => ({
            ...prev,
            [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!user) {
            console.error('User not found. Please log in to add an address.');
            return;
        }

        const requiredFields = [
            'addressName', 'firstName', 'lastName',
            'phone', 'country', 'city', 'street', 'streetNumber',
            'postalCode'
        ];

        for (const field of requiredFields) {
            const value = address[field];
            if (!value) {
                alert(`${field.replace(/([A-Z])/g, ' $1').toUpperCase()} is required.`);
                return;
            }
        }

        try {
            if (addressId) {
                const result = await userService.updateDeliveryAddress(user, setUser, address, addressId, navigate);
                if (result?.success) {
                    const userDataResult = await userService.getUserData(user, setUser);

                    if (!userDataResult.success) {
                        console.error('Failed to fetch updated user data');
                    }

                    clearFields();
                    navigate(-1);
                } else {
                    console.error(`Failed to update address: ${result?.message}`);
                    alert(`Failed to update address: ${result?.message}`);
                }
            } else {
                const result = await userService.addDeliveryAddress(user, setUser, address, navigate);
                if (result.success) {
                    const userDataResult = await userService.getUserData(user, setUser);

                    if (!userDataResult.success) {
                        console.error('Failed to fetch updated user data');
                    }

                    clearFields();
                    navigate(-1);
                } else {
                    console.error(`Failed to add address: ${result.message}`);
                    alert(`Failed to add address: ${result.message}`);
                }
            }
        } catch (error) {
            console.error('An error occurred while saving the address:', error);
            alert('An error occurred while saving the address. Please try again.');
        }
    };

    const handleDelete = async (id: string) => {
        if (!user) {
            console.error('User not found. Please log in to delete an address.');
            return;
        }
        const result = await userService.deleteDeliveryAddress(user, setUser, id, navigate)
        if (result.success) {
            navigate(-1)
        } else {
            alert(result.message)
        }
    }
    const clearFields = () => {
        setAddress({
            addressName: '',
            firstName: '',
            lastName: '',
            phone: '',
            country: '',
            city: '',
            street: '',
            streetNumber: '',
            postalCode: '',
            addressComplement: '',
            asDefault: false
        });
    };

    const handleBack = () => {
        navigate(-1);
    };

    const renderCountrySelect = () => (
        <div className="select-country">
            <select name="country" value={address.country || ''} onChange={handleChange} required>
                <option value="">SELECT A COUNTRY</option>
                {deliveryCountries.map((countryObj, index) => (
                    <option key={index} value={countryObj.country}>{countryObj.country}</option>
                ))}
            </select>
        </div>
    );

    return (
        <Layout>
            <div className="form-container">
                <div className="btn-back-wrapper">
                    <button className="btn-back" onClick={handleBack}>{'< BACK'}</button>
                </div>
                <div className="form-header">
                    <h5>{addressId ? 'EDIT ADDRESS' : 'ADD ADDRESS'}</h5>
                    <button className="btn-delete" onClick={() => handleDelete(addressId || '')}>DELETE</button>
                </div>
                <form className="address-form" onSubmit={handleSubmit}>
                    <div className="address-name-field">
                        <input
                            type="text"
                            name="addressName"
                            placeholder="ADDRESS NAME"
                            value={address.addressName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="name-fields">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="FIRST NAME"
                            value={address.firstName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="LAST NAME"
                            value={address.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="phone-fields">
                        <input
                            type="text"
                            name="phone"
                            placeholder="PHONE NUMBER"
                            value={address.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="address-fields">
                        <input
                            type="text"
                            name="street"
                            placeholder="STREET"
                            value={address.street}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="streetNumber"
                            placeholder="STREET NUMBER"
                            value={address.streetNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <input
                        type="text"
                        name="addressComplement"
                        placeholder="ADDRESS EXTRA DETAILS"
                        value={address.addressComplement}
                        onChange={handleChange}
                    />
                    <input
                        className="short-fields"
                        type="text"
                        name="postalCode"
                        placeholder="ZIP CODE"
                        value={address.postalCode}
                        onChange={handleChange}
                        required
                    />
                    {renderCountrySelect()}
                    <input
                        className="short-fields"
                        type="text"
                        name="city"
                        placeholder="STATE"
                        value={address.city}
                        onChange={handleChange}
                        required
                    />
                    <div className="checkbox-wrapper-48">
                        <label>
                            <input
                                type="checkbox"
                                name="asDefault"
                                checked={address.asDefault}
                                onChange={handleChange}
                            />
                            SAVE AS MY DEFAULT ADDRESS
                        </label>
                    </div>
                    <button type="submit" className="btn-save">SAVE CHANGES</button>
                </form>
            </div>
        </Layout>
    );
};
