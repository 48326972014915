import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useNotionDossiers, useNotionArticles } from '../../../hooks/useNotionArticles';
import './style.scss';

export const DossiersList: React.FC = () => {
    const { dossierId } = useParams<{ dossierId: string }>();
    const { data: dossiers, isLoading: dossiersLoading, error: dossiersError } = useNotionDossiers();
    const { data: articles, isLoading: articlesLoading, error: articlesError } = useNotionArticles();
    const navigate = useNavigate();

    if (dossiersLoading || articlesLoading) return <div>Loading dossier...</div>;
    if (dossiersError || articlesError) return <div>Error: {dossiersError?.message || articlesError?.message}</div>;

    const dossier = dossiers?.find(d => d.id === dossierId);

    if (!dossier) return <div>Dossier not found</div>;

    const dossierArticles = articles?.filter(article => 
        dossier.properties['📰 Articles'].relation.some(rel => rel.id === article.id)
    );

    const getRandomViews = () => Math.floor(Math.random() * 1000) + 100;

    const sortedArticles = dossierArticles?.sort((a, b) => getRandomViews() - getRandomViews());
    const topViewerArticle = sortedArticles?.[0];

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className="dossier-list-container">
            <div className="dossier-list__image" style={{backgroundImage: `url(${dossier.properties.Image.files[0]?.file.url})`}}>
                <button className="dossier-list__back-button" onClick={handleBackClick} aria-label="Go back">
                    &lt; BACK
                </button>
                <div className="dossier-list__image-overlay">
                    <h1 className="dossier-list__title">
                        {dossier.properties.Name.title[0]?.plain_text}
                    </h1>
                    <span className="dossier-list__article-count">
                        {dossier.properties['📰 Articles'].relation.length} Articles
                    </span>
                </div>
            </div>
            <div className="dossier-list__articles-content">
                <div className="dossier-list__articles-section">
                    <h2 className="dossier-list__articles-heading">Top Viewer</h2>
                    {topViewerArticle && (
                        <DossierArticleCard article={topViewerArticle} className="dossier-list__top-viewer" />
                    )}
                    <h2 className="dossier-list__articles-heading">All Articles</h2>
                    <div className="dossier-list__articles-grid">
                        {sortedArticles?.map((article) => (
                            <DossierArticleCard key={article.id} article={article} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DossierArticleCard: React.FC<{ article: any; className?: string }> = ({ article, className = '' }) => (
    <Link to={`/feed/article/${article.id}`} className={`dossier-list__article-item ${className}`}>
        <div className="dossier-list__article-image" style={{backgroundImage: `url(${article.properties.Image.files[0]?.file.url})`}}>
            <div className="dossier-list__article-info">
                <h3 className="dossier-list__article-title">{article.properties.Title.title[0]?.plain_text}</h3>
                <span className="dossier-list__article-category">
                    {article.properties.Categories.multi_select[0]?.name || 'SKINCARE'}
                </span>
            </div>
        </div>
    </Link>
);