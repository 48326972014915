import React, { useEffect, useState } from 'react';
import './style.scss'; // Ensure you have the correct path for your styles

export const ProductLoader: React.FC = () => {
    const [loadingPercentage, setLoadingPercentage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingPercentage((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prev + 1;
            });
        }, 25);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loader-container">
            <div className="loader">
                <div className="percentage">{loadingPercentage}%</div>
            </div>
        </div>
    );
};
