import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TGender } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import blackLogo from '../../../assets/icons/blackLogo.svg';
import { SignupNavbar } from '../../../components/navbars/SignupNavbar/SignupNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import '../style.scss';

export const SPF_Gender: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState<TGender | null>(null);

  useEffect(() => {
    if (user?.SPF?.gender) {
      setSelectedGender(user.SPF.gender);
    }
    console.log('Current skinProfileAtom state:', skinProfile);
    console.log('user', user);
  }, [user, skinProfile]);

  const handleGenderSelect = (gender: TGender) => {
    setSelectedGender(gender);
  };

  const goToNext = () => {
    if (selectedGender) {
      setSkinProfile({ ...skinProfile, gender: selectedGender });
      navigate('/spf-form/spf-age');
    }
  };

  return (
    <div className="headerBlockFormsSkin " style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <SignupNavbar />
      <div className="form-skin-profile-container">
        <div className="form-container-skin">
          <StepIndicator currentStep={1} />
          <h1 className="gender-title">HOW YOU DEFINE YOURSELF?</h1>
          <h5 className="gender-subtitle">Your genre allows us to give you more precise recommendations</h5>
          <div className="input-group">
            <button
              className={`gender-button ${selectedGender === 'Female' ? 'selected' : ''}`}
              onClick={() => handleGenderSelect('Female')}
            >
              Female
            </button>
          </div>

          <div className="input-group">
            <button
              className={`gender-button ${selectedGender === 'Male' ? 'selected' : ''}`}
              onClick={() => handleGenderSelect('Male')}
            >
              Male
            </button>
          </div>

          <div className="input-group">
            <button
              className={`gender-button ${selectedGender === 'Non binary' ? 'selected' : ''}`}
              onClick={() => handleGenderSelect('Non binary')}
            >
              Non binary
            </button>
          </div>

          <div className="input-group">
            <button
              className={`gender-button ${selectedGender === 'I don’t want to define myself' ? 'selected' : ''}`}
              onClick={() => handleGenderSelect('I don’t want to define myself')}
            >
              I don’t want to define myself
            </button>
          </div>
          <button className="next-button-form" disabled={!selectedGender} onClick={goToNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
