import React, { useState } from 'react';
import { Layout } from '../../../layout/layout';
import userService from '../../../../../service/UserService';
import { userAtom } from '../../../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import './style.scss';

export const ChangePassword: React.FC = () => {
    const [user, setUser] = useAtom(userAtom);
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [showPasswords, setShowPasswords] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });
    const [error, setError] = useState<string | null>(null);
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswordData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === 'newPassword') {
            const newPasswordErrors = validatePasswordStrength(value);
            setPasswordErrors(newPasswordErrors);
        }
    };

    const togglePasswordVisibility = (field: 'currentPassword' | 'newPassword' | 'confirmPassword') => {
        setShowPasswords((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const isFormValid = () => {
        return (
            passwordData.currentPassword &&
            passwordData.newPassword &&
            passwordData.confirmPassword
        );
    };

    const clearFields = () => {
        setPasswordData({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
        setPasswordErrors([]);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const validatePasswordStrength = (password: string) => {
        const errors: string[] = [];
        if (password.length < 8) errors.push("Minimum 8 characters.");
        if (!/[A-Z]/.test(password)) errors.push("At least one uppercase letter.");
        if (!/[a-z]/.test(password)) errors.push("At least one lowercase letter.");
        if (!/[0-9]/.test(password)) errors.push("At least one number.");
        return errors;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!user) {
            setError('User not found. Please log in again.');
            return;
        }

        setPasswordErrors([]);

        const newPasswordErrors = validatePasswordStrength(passwordData.newPassword);
        setPasswordErrors((prev) => [...prev, ...newPasswordErrors]);

        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setPasswordErrors((prev) => [...prev, "New password and confirmation do not match."]);
        }

        if (newPasswordErrors.length > 0 || passwordData.newPassword !== passwordData.confirmPassword) {
            return;
        }

        if (isFormValid()) {
            const result = await userService.modifyPassword(
                user,
                setUser,
                passwordData.currentPassword,
                passwordData.newPassword
            );

            if (result.success) {
                clearFields();
                navigate(-1);
            } else {
                setError(result.message);
            }
        } else {
            setError('Please fill all fields and make sure new passwords match.');
        }
    };


    return (
        <Layout>
            <div className="password-form-container">
                <button className="password-back-button" onClick={handleBack}>{'< BACK'}</button>

                <div className="form-header">
                    <h5>CHANGE PASSWORD</h5>
                </div>
                <form className="password-form" onSubmit={handleSubmit}>
                    <div className="password-field">
                        <input
                            type={showPasswords.currentPassword ? 'text' : 'password'}
                            name="currentPassword"
                            placeholder="CURRENT PASSWORD"
                            value={passwordData.currentPassword}
                            onChange={handleChange}
                        />
                        <button
                            type="button"
                            className="toggle-visibility"
                            onClick={() => togglePasswordVisibility('currentPassword')}
                        >
                            {showPasswords.currentPassword ? 'HIDE' : 'SEE'}
                        </button>
                    </div>
                    <div className="password-field">
                        <input
                            type={showPasswords.newPassword ? 'text' : 'password'}
                            name="newPassword"
                            placeholder="NEW PASSWORD"
                            value={passwordData.newPassword}
                            onChange={handleChange}
                        />
                        <button
                            type="button"
                            className="toggle-visibility"
                            onClick={() => togglePasswordVisibility('newPassword')}
                        >
                            {showPasswords.newPassword ? 'HIDE' : 'SEE'}
                        </button>
                    </div>
                    <div className="password-field">
                        <input
                            type={showPasswords.confirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            placeholder="CONFIRM NEW PASSWORD"
                            value={passwordData.confirmPassword}
                            onChange={handleChange}
                        />
                        <button
                            type="button"
                            className="toggle-visibility"
                            onClick={() => togglePasswordVisibility('confirmPassword')}
                        >
                            {showPasswords.confirmPassword ? 'HIDE' : 'SEE'}
                        </button>
                    </div>
                    {passwordErrors.length > 0 && (
                        <div className="error-message">
                            {passwordErrors.map((error, index) => (
                                <div key={index}>{error}</div>
                            ))}
                        </div>
                    )}
                    <div className="password-advice">
                        <h5>ADVICES</h5>
                        <span>MIN. 8 CHARACTERS</span>
                        <span>ONE UPPERCASE LETTER</span>
                        <span>ONE LOWERCASE LETTER</span>
                        <span>ONE NUMBER</span>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <button type="submit" className="submit-button" disabled={!isFormValid()}>SAVE PASSWORD</button>
                </form>
            </div>
        </Layout>
    );
};
