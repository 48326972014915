import React from 'react';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import { IAddress } from '../../../../../types/TInterfaces';
import { Link } from 'react-router-dom';
import './style.scss';

export const AddressBook: React.FC = () => {
    const [user] = useAtom(userAtom);

    const addresses: IAddress[] = user?.addresses || [];

    return (
        <div className="address-book">
            <div className="header">
                <h4 className="title">Address Book</h4>
                <Link to="add-address" className="btn-add">
                    + ADD
                </Link>
            </div>
            <div className="divider"></div>
            <div className="address-list">
                {addresses.length > 0 ? (
                    addresses.map((address, index) => (
                        <div key={index} className="btn-address">
                            <div className="address-info">
                                <div className="address-name">{index === 0 ? 'DEFAULT ADDRESS' : address.addressName}</div>
                                <div>{`${address.firstName} ${address.lastName}`}</div>
                                <div>{`${address.street} ${address.streetNumber}`}</div>
                                <div>{`${address.postalCode}, ${address.city}, ${address.country}`}</div>
                                <Link to={`add-address/${address._id}`} className="btn-edit-address">EDIT</Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No addresses found.</div>
                )}
            </div>
        </div>
    );
};
