import './style.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import { setLocalStorageItem } from '../../utils/localStoragUtil';
import React, { useState, useEffect } from 'react';
import { config } from 'config';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import { SignupNavbar } from '../../components/navbars/SignupNavbar/SignupNavbar';
import blackLogo from '../../assets/icons/blackLogo.svg';
import globalService from '../../service/GlobalService';
import { loadLocalCart, deleteLocalCart } from '../../service/localCartService';
import UserApi from 'service/API/UserApi';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';

export const LogInPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [, setUser] = useAtom(userAtom);

  useEffect(() => {
    const state = location.state as { errorMessage?: string, returnPath?: string };
    if (state?.errorMessage) {
      setError(state.errorMessage);
    }
  }, [location]);

  const resetError = (error: string | null, setError: (error: null) => void) => {
    // Resets the error state by setting it to null if there is an existing error.
    if (error) {
      setError(null);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    resetError(error, setError);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    resetError(error, setError);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await UserApi.userLogin(email, password);
      if (response.success && response.data) {
        const { user, accessToken, refreshToken } = response.data;
        console.log('user: ', user);
        // First save user data and tokens
        await globalService.saveUserAndTokens(accessToken, refreshToken, user, setUser);

        // Handle cart merging
        const localCart = loadLocalCart();

        if (localCart.length > 0) {
          const newUser = { ...user };

          // Only add items that don't exist in the user's cart
          for (const localItem of localCart) {
            const existingItem = newUser.cart.find((item: any) =>
              item.product._id === localItem.product._id
            );

            if (!existingItem) {
              // Add new item to user's cart
              newUser.cart.push(localItem);
              // Update database
              await UserApi.addProductToCart(setUser, localItem);
            }
            // If item exists, keep the user's existing quantity
          }

          await globalService.saveUser(newUser, setUser);
          // Only delete local cart after successful merge
          deleteLocalCart();
        }

        // Handle navigation
        const state = location.state as { returnPath?: string };
        navigate(state?.returnPath || '/shop');
      } else {
        console.error('err: ', response);
        setError(response?.message || 'Invalid email or password');
      }
    } catch (err: any) {
      console.error('Login error: ', err);
      setError('Invalid email or password');
    }
  };

  const inputErrorClass = error ? 'error' : '';

  return (
    <>
      <div className="headerBlock" style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
        <HomepageNavbar />
        <div className="sign-in-container">
          <div className="form-container">
            <img className="group" alt="Group" src={blackLogo} />

            <h1 className="login-title">SIGN IN</h1>
            <p className="login-subtitle">WELCOME, SIGN IN OR CREATE AN ACCOUNT TO CONTINUE YOUR SKINCARE ROUTINE</p>
            <form onSubmit={handleLogin}>

              <div className="input-group">
                <input
                  type="email"
                  id="email"
                  placeholder="EMAIL ADDRESS"
                  value={email}
                  onChange={handleEmailChange}
                  className={`input ${inputErrorClass}`}
                  required
                />
              </div>

              <div className="input-group">
                <div className="password-container">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="PASSWORD"
                    value={password}
                    onChange={handlePasswordChange}
                    className={`input ${inputErrorClass}`}
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className={`show-password-btn ${inputErrorClass}`}
                  >
                    {showPassword ? 'HIDE' : 'SEE'}
                  </button>
                </div>
                {error && <p className="error-message">{error}</p>}
              </div>

              <button type="submit" className="sign-in-button" disabled={!email || !password}>
                SIGN IN
              </button>
            </form>

            {/* <a href="/forgot-password" className="forgot-password">
              FORGOT PASSWORD?
            </a> */}

            <p className="sign-up-text">
              Need an account?{' '}
              <a href="/signup" className="sign-up-link">
                Sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
