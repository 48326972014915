import React, { useState, useEffect, useCallback, useRef } from 'react';
import './style.scss';
import CountBackground from 'assets/icons/cartCountBackground.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { userAtom } from '../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { handleLogOut } from '../utils/authUtils';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import WhiteLogo from 'assets/icons/whiteLogo.svg';
import Cart from '../../cart/cart';
import { IProductInCart } from '../../../types/TInterfaces';
import userService from 'service/UserService';
import { loadLocalCart } from '../../../service/localCartService';
import { cartItemsAtom } from '../../../stores/UserProfile';

export const HomepageNavbar = () => {
  const [user, setUser] = useAtom(userAtom);
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);
  const [menuVisible, setMenuVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true); // New state for navbar visibility
  const location = useLocation();
  const navigate = useNavigate();
  const { data: articles } = useNotionArticles();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const cartRef = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const isProductPage = location.pathname.includes('/product');
  const isCheckoutPage = location.pathname.includes('/checkout');
  const isLoginPage = location.pathname.includes('/login');
  const isSignUpPage = location.pathname.includes('/signup');
  const isFeedPage = location.pathname.includes('/feed');
  const isShopPage = location.pathname.includes('/shop');
  const isProfilePage = location.pathname.includes('/profile');
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (user) {
      setCartItems(user.cart || []);
    } else {
      setCartItems(loadLocalCart());
    }
  }, [user, setCartItems]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuVisible && menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
      if (cartVisible && (cartRef.current && !cartRef.current.contains(event.target as Node))) {
        setCartVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuVisible, cartVisible]);

  const logout = () => handleLogOut(setUser, navigate);
  const toggleCart = () => setCartVisible(!cartVisible);
  const updateCartItems = useCallback((newCartItems: IProductInCart[] | ((prevItems: IProductInCart[]) => IProductInCart[])) => {
    setCartItems(newCartItems);
  }, []);
  const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  const menuItems = [
    { title: 'Shop +', target: '/shop' },
    { title: 'Bestsellers', target: '/bestsellers' },
    { title: 'Feed', target: '/feed' },
    ...(user && !userService.isAnonymousUser(user) ? [{ title: 'Profile', target: '/profile/skin-resume' }] : []),
  ];

  // Scroll listener to hide/show navbar
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsNavbarVisible(false); // Scrolling down, hide navbar
      } else {
        setIsNavbarVisible(true); // Scrolling up, show navbar
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className={`navbar ${isNavbarVisible ? 'visible' : 'hidden'}`}>
        <div
          className="navbar-left-logo"
          style={{ visibility: isHomePage && !isMobile ? 'hidden' : 'visible' }}
        >
          <Link to="/">
            <img className="navbar-logo" src={WhiteLogo} alt="White Logo" />
          </Link>
          {!isLoginPage && !isShopPage && !isProfilePage && !isHomePage && !isSignUpPage && (
            <button onClick={() => navigate(-1)} className="back-button-home">
              &lt; BACK
            </button>
          )}
        </div>
        <span onClick={() => setMenuVisible(!menuVisible)} className="navbar-item">MENU</span>

        <nav className="navbar-right">
          {!isMobile && !isLoginPage && (
            <Link to={'/shop'} className="navbar-item">SHOP</Link>
          )}
          {user ? (
            !isMobile && (
              <Link to="#" onClick={logout} className="navbar-item">LOG OUT</Link>
            )
          ) : (
            !isMobile && !isLoginPage && (
              <Link to={'/login'} className="navbar-item">SIGN UP</Link>
            )
          )}
          <span onClick={toggleCart} className="navbar-item cart-container">
            CART
            <span className="cart-count" style={{ backgroundImage: `url(${CountBackground})` }}>
              {cartCount}
            </span>
          </span>
        </nav>
      </div>

      <div ref={cartRef}>
        <Cart isOpen={cartVisible} onClose={() => setCartVisible(false)} cartItems={cartItems} setCartItems={updateCartItems} />
      </div>

      <div ref={menuRef} className="menuContainer" style={{ display: menuVisible ? 'flex' : 'none', flexDirection: 'column' }}>
        <button onClick={() => setMenuVisible(false)} className="menuContainerCloseButton">
          <p className="menuContainerCloseText">CLOSE</p>
        </button>

        <ul>
          {menuItems.map((item, index) => (
            <li className="menuContainerMenuItem" key={index} onClick={() => navigate(item.target)}>
              {item.title}
            </li>
          ))}
        </ul>

        {articles?.slice(0, 2).map((article) => (
          <Link to={`/feed/article/${article.id}`} key={article.id} style={{ textDecoration: 'none', color: 'white' }}>
            <div className="menuContainerImgBlock" style={{ backgroundImage: `url(${article.properties.Image.files[0]?.file.url})` }}>
              <div className='dossiersContainer'>
                {article?.dossierNames?.length ?? 0 > 0 ? (
                  article?.dossierNames?.map((name, index) => (
                    <div key={index} className="menuContainerImgCategoryBlock">
                      <p className="menuContainerImgCategory">
                        <span className="dossierName">
                          {name.toUpperCase()}
                        </span>
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="menuContainerImgCategoryBlock">
                    <p className="menuContainerImgCategory">
                      <span>NO DOSSIERS</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="menuContainerImgHeaderContainer">
                <p className="menuContainerImgHeader">{article.properties.Title.title[0]?.plain_text}</p>
              </div>
            </div>
          </Link>
        ))}

        <div className="social-links">
          <p className="menuContainerSocialLink">Instagram</p>
          <p className="menuContainerSocialLink">Tiktok</p>
          <p className="menuContainerSocialLink">Linkedin</p>
        </div>
      </div>
    </>
  );
};
