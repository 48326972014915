import midnight from '../Midnight/Midnight_Banner_Desktop.webp'
import celimax from '../productOne/productOne.jpg'

export const backgroundBrands = [
    {
        "name": "Midnight 00",
        "image": midnight
    },
    {
        "name": "celimax",
        "image": celimax
    },

]
