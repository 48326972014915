// ShopHeadBestsellers.js
import './style.scss';
import { useLocation } from 'react-router-dom';
import HeaderBackgroundImage from 'assets/images/101.jpg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';

interface ShopHeadBestsellersProps {
  backgroundImage?: string;
}

export const ShopHeadBestsellers = ({ backgroundImage = HeaderBackgroundImage }: ShopHeadBestsellersProps) => {
  const location = useLocation();
  const newBrand = location.pathname.includes('/brand/');
  const customState = location.state as { customTitle?: string; headerBackground?: string } | null;

  return (
    <div
      className="shopHeaderBlock"
      style={{
        backgroundImage: `url(${customState?.headerBackground || backgroundImage})`
      }}
    >
      <HomepageNavbar />
      <div className="shopHeaderBlock-content">
        <div className="shopLogo-container">
          <p className="shopHeaderAdvice">
            {customState?.customTitle || (newBrand ? "" : "BESTSELLERS")}
          </p>
        </div>
        <div>
          <p className="shopHeaderRight-text">{newBrand ? "" : "Finest Selection"}</p>
        </div>
      </div>
      <div className="shopHeaderBottomArea">
        <div />
      </div>
    </div>
  );
};
