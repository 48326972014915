import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import { ProductLoader } from '../../../pages/ProductDetails/ProductLoader';
import './style.scss';

export const ArticleDetail: React.FC = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const { data: articles, isLoading, error } = useNotionArticles();
  const navigate = useNavigate();

  if (isLoading) return <div className="article-loader"><ProductLoader /></div>;
  if (error) return <div>Error: {error.message}</div>;

  const article = articles?.find((a) => a.id === articleId);

  if (!article) return <div>Article not found</div>;

  const renderContent = (content: any[]) => {
    return content.map((block, index) => {
      switch (block.type) {
        case 'paragraph':
          return (
            <p key={index} className="article-paragraph">
              {block.content?.map((textBlock: any, i: number) => (
                <span
                  key={i}
                  style={{
                    fontWeight: textBlock.bold ? 'bold' : 'normal',
                    fontStyle: textBlock.italic || textBlock.href ? 'italic' : 'normal',
                    textDecoration: textBlock.underline ? 'underline' : 'none',
                    color: textBlock.color !== 'default' ? textBlock.color : 'inherit',
                  }}
                >
                  {textBlock.href ? (
                    <a href={textBlock.href} className="article-link">
                      {textBlock.text}
                    </a>
                  ) : (
                    textBlock.text
                  )}
                </span>
              ))}
            </p>
          );
        case 'image':
          return (
            <figure key={index} className="article-image-container">
              <img src={block.url} alt={block.caption} className="article-content-image" />
              {block.caption && <figcaption className="article-image-caption">{block.caption}</figcaption>}
            </figure>
          );
        default:
          return null;
      }
    });
  };

  const handleBackClick = () => {
    navigate(-1); // This will navigate back to the previous page
  };

  return (
    <div className="article-detail-container">
      <button className="back-button" onClick={handleBackClick} aria-label="Go back">
        &lt; BACK
      </button>
      <div className="article-image-section">
        <img src={article.properties.Image.files[0]?.file.url} alt={article.properties.Title.title[0]?.plain_text} className="article-image" />
        <div className="article-image-overlay"></div>
        <div className="article-meta">
          <span className="article-category">{article.properties.Categories.multi_select[0]?.name || 'SKINCARE'}</span>
          <h1 className="article-title-detail">{article.properties.Title.title[0]?.plain_text}</h1>
        </div>
      </div>
      <div className="article-content-section">
        {/* <div className="article-actions">
                    <button className="share-button">SHARE</button>
                    <button className="save-button">+ SAVE ARTICLE</button>
                </div> */}
        <div className="article-content">{renderContent(article.content)}</div>
      </div>
    </div>
  );
};
