import React, { ButtonHTMLAttributes } from "react";
import './style.scss'

interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  buttonColor?: 'white' | 'lightYellow';
  marginBottom?: number;
  marginTop?: number;
}

export const DefaultButton: React.FC<DefaultButtonProps> = ({
  text,
  buttonColor,
  marginBottom,
  marginTop,
  ...props
}) => {
  return (
    <button
      className="defaultButton"
      style={{
        backgroundColor: buttonColor === 'lightYellow' ? '#F7F6E6' : buttonColor === 'white' ? '#fff' : '#000',
        color: buttonColor === 'lightYellow' ? '#000' : buttonColor === 'white' ? '#000' : '#fff',
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0
      }}
      {...props}
    >
      {text}
    </button>
  )
}
