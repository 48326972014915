import { useState, useEffect, useMemo } from 'react';
import productsApi from '../service/API/ProductsApi';
import { ShopProduct } from '../types/ShopInterfaces';

const useRandomProducts = (quantity: number, brandName?: string) => {
  const [randomProducts, setRandomProducts] = useState<ShopProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Memoize the brandName to prevent unnecessary re-fetches
  const memoizedBrandName = useMemo(() => brandName, [brandName]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        if (memoizedBrandName) {
          const result = await productsApi.getProductsByBrand(memoizedBrandName);
          if (result.success && result.data) {
            setRandomProducts(result.data.slice(0, quantity));
          }
        } else {
          const result = await productsApi.getRandomProducts(quantity);
          if (result.success && result.data) {
            setRandomProducts(result.data);
          }
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [quantity, memoizedBrandName]);

  return { randomProducts, loading, error };
};

export default useRandomProducts;