import './style.scss';
import useRandomProducts from 'hooks/randomProducts';
import { useEffect } from 'react';
import { HeadSection } from 'components/homepage/HeadSection/HeadSection';
import { BestSellersSection } from 'components/homepage/BestSellersSection/BestSellersSection';
import { ArticlesCarousel } from 'components/homepage/ArticlesCarousel/ArticlesCarousel';
import { ScanningFeature } from 'components/homepage/ScanningFeature/ScanningFeature';
import { AdviceSection } from 'components/homepage/AdviceSection/AdviceSection';
import { SaferIngredients } from 'components/homepage/SaferIngredients/SaferIngredients';
import { LatestTrends } from 'components/homepage/LatestTrends/LatestTrends';
import { ArticlesPreview } from 'components/homepage/ArticlesPreview/ArticlesPreview';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import { BestSellersBrandSection } from 'components/homepage/BestSellersSection/BestSellersBrandSection';
export const Homepage = () => {
  const { randomProducts } = useRandomProducts(10);

  return (
    <>
      <HeadSection />
      <BestSellersSection />
      {/* <ScanningFeature /> */}
      {/* <BestSellersBrandSection /> */}
      <ArticlesCarousel />
      {/* <AdviceSection /> */}
      <SaferIngredients />
      <LatestTrends />
      <ArticlesPreview />
      <FooterSection />
    </>
  );
};
