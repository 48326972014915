import React from 'react';
import styles from './checkout.module.scss';
import { ShippingAddress, AddressCardProps } from '../../types/TInterfaces';

export const AddressCard: React.FC<AddressCardProps> = ({ shippingAddress, isSelected, onEdit }) => {
    return (
        <div className={styles.addressCard}>
            <div className={styles.cardHeader}>
                <h3>ADDRESS</h3>
                {isSelected && (
                    <div className={styles.selectedIcon}>
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="black" />
                            <path d="M9 12.5l2.5 2.5L15 10" stroke="white" strokeWidth="2" fill="none" />
                        </svg>
                    </div>
                )}
            </div>
            <div className={styles.addressDetails}>
                <p>{`${shippingAddress.first_name} ${shippingAddress.last_name}`}</p>
                <p>{`${shippingAddress.street} ${shippingAddress.streetNumber}`}</p>
                <p>{`${shippingAddress.postalCode} ${shippingAddress.city}, ${shippingAddress.country}`}</p>
            </div>
            <button className={styles.editButton} onClick={onEdit}>EDIT</button>
        </div>
    );
};

