import React, { useState, useEffect } from 'react';
import './style.scss';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import { useNavigate } from 'react-router-dom';
import { ProductLoader } from '../../../pages/ProductDetails/ProductLoader';

export const ArticlesPreview: React.FC = () => {
  const { data: articles, isLoading, error } = useNotionArticles();
  const [selectedArticleIndex, setSelectedArticleIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setSelectedArticleIndex(prevIndex => (prevIndex + 1) % Math.min(3, articles?.length || 0));
        setFade(false);
      }, 500);
    }, 3000);
    return () => clearInterval(interval); // Cleanup to reset the interval
  }, [selectedArticleIndex, articles?.length]); // Reset interval on selectedArticleIndex change


  if (isLoading) return null;
  if (error || !articles || articles.length === 0) {
    console.error('failed to load articles preview:', error);
    return null;
  }
  const selectedArticle = articles[selectedArticleIndex];
  const backgroundImageUrl = selectedArticle?.properties?.Image?.files?.[0]?.file?.url || '';

  const handleArticleClick = (articleId: string) => {
    window.open(`/feed/article/${articleId}`, '_blank');
  };

  const handleSeeAllArticles = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation(); // Prevents the click from bubbling up to the parent container
    navigate('/feed');
  };

  const handleStoryClick = (index: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent event from bubbling up to parent elements
    setSelectedArticleIndex(index);
  };


  return (
    <div
      onClick={() => handleArticleClick(selectedArticle?.id)}
      className="articlePriviewContainer">
      <div
        className="articlePreviewImg"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          transition: 'background-image 1s ease-in-out',
        }}
        onClick={() => handleArticleClick(selectedArticle?.id)}
      />
      <div className={`APcontentContainer ${fade ? 'fade' : ''}`}>
        <div className="storiesContainer">
          {articles.slice(0, 3).map((article, index) => (
            <div
              key={article.id}
              onClick={(event) => handleStoryClick(index, event)}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <div className={index === selectedArticleIndex ? 'storyIMG-container' : 'storyIMG-placeholder'}>
                <div
                  className="storyIMG"
                  style={{
                    backgroundImage: `url(${article?.properties?.Image?.files?.[0]?.file?.url || ''})`
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // Also stop propagation here to prevent triggering handleArticleClick
                    handleArticleClick(article?.id);
                  }}

                />
              </div>
              <p className="storyNumber">0{index + 1}</p>
            </div>
          ))}
        </div>

        <div className="textAndButtonContainer">
          <div className="textBlock" onClick={() => handleArticleClick(selectedArticle?.id)}>

            <p className="skinCareHeader">
              {selectedArticle?.dossierNames?.length ?? 0 > 0 ? (
                selectedArticle?.dossierNames?.map((name, index, array) => (
                  <React.Fragment key={index}>
                    <span className="dossierName">
                      {name}
                    </span>
                    {index < array.length - 1 && ' | '}
                  </React.Fragment>
                ))
              ) : (
                <span>No Dossiers</span>
              )}
            </p>

            <p className="skinCareText">
              {selectedArticle?.properties?.Title?.title?.[0]?.plain_text || 'Default Title'}
            </p>
          </div>
          <button className="APblackButton" onClick={handleSeeAllArticles}>
            <p>SEE ALL ARTICLES</p>
          </button>
        </div>
      </div>
    </div>
  );
};