import './style.scss';
import HeaderBackgroundImage from 'assets/images/formBackground.png';
import MainText from 'assets/textFont/novemberFavorite.svg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { ProductAdvice } from 'shared/elements/ProductAdvice';

export const ShopHeadSection = () => {
  return (
    <div className="shopHeaderBlock" style={{ backgroundImage: `url(${HeaderBackgroundImage})` }}>
      <HomepageNavbar />
      <div className="shopHeaderBlock-content">
        <div className="shopLogo-container">
          <p className="shopHeaderTitle">Oli’s Lab</p>
        </div>

        <div className="shopHeaderCenterBlock">
          <div className="shopMainText" style={{ backgroundImage: `url(${MainText})` }} />
        </div>

        <div>
          <p className="shopHeaderRight-text">Discover more</p>
        </div>
      </div>
      <div className="shopHeaderBottomArea">
        <div />
        {/* <div className='shopHeaderScrollNumberIndicator'>
          <ScrollNumberIndicator count={4} fontSize={12} color="white" />
        </div> */}
        <ProductAdvice />
      </div>
    </div>
  );
};
