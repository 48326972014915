import './style.scss';
import { useCallback, useEffect, useState } from 'react';
import { IProductInCart } from '../../types/TInterfaces';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import userService from 'service/UserService';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import emptyCart from '../../assets/icons/emptyCart.svg';
import BlackCounter from 'assets/images/blackCounter.png';
import { loadLocalCart, removeFromLocalCart, updateLocalCartItemQuantity } from '../../service/localCartService';

/**
 * Interface for the Cart component props.
 */
interface CartProps {
  isOpen: boolean;
  onClose: () => void;
  cartItems: IProductInCart[];
  setCartItems: (items: IProductInCart[] | ((prevItems: IProductInCart[]) => IProductInCart[])) => void;
}

const CART_CONSTANTS = {
  DEFAULT_QUANTITY: '0',
  DEFAULT_SIZE: '250ML',
  CURRENCY: '€',
  NO_PRICE: 'NO PRICE',
  NO_TITLE: 'NO TITLE',
  NO_DESCRIPTION: 'NO DESCRIPTION',
  EMPTY_CART_MESSAGE: 'YOUR SHOPPING BAG IS EMPTY'
} as const;

/**
 * The Cart component.
 */
const Cart: React.FC<CartProps> = ({ isOpen, onClose, cartItems, setCartItems }) => {
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [tempQuantity, setTempQuantity] = useState<number | null>(null);
  const [currentUser, setUser] = useAtom(userAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      setCartItems(currentUser.cart);
    } else {
      const localCartItems = loadLocalCart();
      setCartItems(localCartItems);
    }
  }, [currentUser, setCartItems]);

  /**
   * Function to get the total number of items in the cart.
   * @returns {number} The total number of items.
   */
  const getTotalItems = useCallback(() =>
    cartItems.reduce((total, item) => total + item.quantity, 0),
    [cartItems]
  );

  /**
 * Function to update the cart items.
 * @param {IProductInCart[]} updatedCartItems - The updated cart items.
 */
  const updateCart = (updatedCartItems: typeof cartItems) => {
    setCartItems(updatedCartItems);
  };

  /**
 * Function to get the total price of the items in the cart.
 * @returns {string} The total price.
 */
  const getTotalPrice = useCallback(() => {
    if (cartItems.length === 0) return '0.00';
    const total = cartItems.reduce(
      (total, item) => total + item.quantity * Number(item.product.price),
      0
    );
    return total.toFixed(2);
  }, [cartItems]);

  /**
 * Function to remove an item from the cart.
 * @param {string} id - The ID of the item to remove.
 */
  const removeItemFromCart = async (id: string) => {
    if (currentUser) {
      const product = cartItems.find(item => item.product._id === id);
      if (!product) {
        console.error("Product not found in cart.");
        return;
      }
      const result = await userService.removeFromCart(currentUser, setUser, product, navigate);

      if (result.success) {
        const updatedCartItems = cartItems.filter((item) => item.product._id !== id);
        updateCart(updatedCartItems);
      } else {
        console.error("Failed to remove product from cart:", result.message);
      }
    } else {
      const updatedCart = removeFromLocalCart(id);
      updateCart(updatedCart);
    }
  };

  /**
 * Function to handle the editing of the quantity of an item.
 * @param {string} id - The ID of the item to edit.
 */
  const handleEditQuantity = (id: string) => {
    setEditingItemId(id);
    const item = cartItems.find((item) => item.product._id === id);
    if (item) {
      setTempQuantity(item.quantity);
    }
  };

  /**
   * Function to change the quantity of an item.
   * @param {string} id - The ID of the item to change the quantity of.
   * @param {number} newQuantity - The new quantity.
   */
  const changeItemQuantity = (id: string, newQuantity: number) => {
    setTempQuantity(Math.max(1, newQuantity));
  };

  /**
 * Function to handle the approval of a quantity change.
 * @param {string} id - The ID of the item to approve the quantity change for.
 */
  const handleApproveChange = async (id: string) => {
    try {
      const product = cartItems.find((item) => item.product._id === id);
      if (!product) {
        console.error('Product not found in cart');
        return;
      }

      if (currentUser) {
        const updatedCartItems = cartItems.map((item) =>
          item.product._id === id ? { ...item, quantity: tempQuantity! } : item
        );
        updateCart(updatedCartItems);
        setEditingItemId(null);
        const result = await userService.updateCart(currentUser, setUser, product.product, tempQuantity!, navigate);
        if (!result.success) {
          console.error("Failed to update cart on the server:", result.message);
        }
      } else {
        const updatedCart = updateLocalCartItemQuantity(id, tempQuantity!);
        updateCart(updatedCart);
        setEditingItemId(null);
      }
    } catch (error) {
      console.error('Failed to update cart:', error);
      // TODO: Handle error
    }
  };

  /**
 * Function to handle the checkout process.
 */
  const handleCheckout = () => {
    navigate('/checkout');
  };

  if (!isOpen) {
    return null;
  }

  /**
 * Function to generate the cart items.
 * @param {IProductInCart[]} cartItems - The cart items to generate.
 * @returns {React.ReactNode} The generated cart items.
 */
  const generateCartItems = (cartItems: IProductInCart[]): React.ReactNode => {
    if (cartItems.length === 0) {
      return (
        <div className="empty-cart">
          <img src={emptyCart} alt="Empty Cart" className="empty-cart-image" />
          <p className="empty-cart-text">{CART_CONSTANTS.EMPTY_CART_MESSAGE}</p>
        </div>
      );
    }

    return cartItems.map((item) => (
      <div key={item?.product._id} className="cart-item">
        {renderItemImage(item)}
        <div className="item-details">
          {renderItemHeader(item)}
          {renderItemDescription(item)}
          <p>{renderItemSize(item)}</p>
          {renderItemActions(item)}
        </div>
        <div className="item-price">
          {renderItemQuantity(item)} * {renderItemPrice(item)}
        </div>
      </div>
    ));
  };

  /**
 * Renders the image of a cart item.
 * @param {IProductInCart} item - The cart item to render the image for.
 * @returns {JSX.Element} The rendered image.
 */
  const renderItemImage = (item: IProductInCart) => (
    <img
      src={item?.product?.imgUrl || emptyCart}
      alt={item?.product?.title || "Empty Cart"}
      className="cart-item-image"
    />
  );

  /**
 * Renders the header of a cart item.
 * @param {IProductInCart} item - The cart item to render the header for.
 * @returns {JSX.Element} The rendered header.
 */
  const renderItemHeader = (item: IProductInCart) => (
    <h5 className='item-header'>{item?.product.title || CART_CONSTANTS.NO_TITLE}</h5>
  );

  const renderItemSize = (item: IProductInCart) => {
    return item?.product.quantity ? `${item?.product.quantity}ML` : CART_CONSTANTS.DEFAULT_SIZE;
  };

  /**
   * Renders the actions of a cart item.
   * @param {IProductInCart} item - The cart item to render the actions for.
   * @returns {JSX.Element} The rendered actions.
   */
  const renderItemActions = (item: IProductInCart) => (
    editingItemId === item?.product._id ? (
      <div className="quantity-controls">
        <button onClick={() => changeItemQuantity(item?.product._id, tempQuantity! - 1)}>-</button>
        <span>{tempQuantity}</span>
        <button onClick={() => changeItemQuantity(item?.product._id, tempQuantity! + 1)}>+</button>
        <button onClick={() => handleApproveChange(item?.product._id)}>APPROVE</button>
      </div>
    ) : (
      <div className="default-controls">
        <button onClick={() => handleEditQuantity(item?.product._id)} style={{ fontWeight: 700 }}>CHANGE</button>
        <button onClick={() => removeItemFromCart(item?.product._id)}>DELETE</button>
      </div>
    )
  );

  /**
 * Renders the price of a cart item.
 * @param {IProductInCart} item - The cart item to render the price for.
 * @returns {JSX.Element} The rendered price.
 */
  const renderItemPrice = (item: IProductInCart) => (
    <span>{CART_CONSTANTS.CURRENCY}{item?.product.price ? Number(item.product.price).toFixed(2) : CART_CONSTANTS.NO_PRICE}</span>
  );

  const renderItemDescription = (item: IProductInCart) => (
    <span className="item-description">{item?.product.description || CART_CONSTANTS.NO_DESCRIPTION}</span>
  );

  const renderItemQuantity = (item: IProductInCart) => item?.quantity || CART_CONSTANTS.DEFAULT_QUANTITY;

  const cartHeader = () => {
    const isCartEmpty = getTotalItems() === 0;

    return (
      <div className={isCartEmpty ? "cart-header-empty" : "cart-header"}>
        <span className="favorites-btn" style={{ visibility: 'hidden' }}>FAVORITES</span>
        <span className="close-btn" onClick={onClose}>
          CLOSE
          <div className="item-count" style={{ backgroundImage: `url(${BlackCounter})` }}>
            {getTotalItems()}
          </div>
        </span>
      </div>
    );
  };


  const cartFooter = () => (
    cartItems.length > 0 ? (
      <>
        <div className="subtotal">
          <strong>SUBTOTAL ({getTotalItems()})</strong>
          <span className='subtotal-price'>{CART_CONSTANTS.CURRENCY}{getTotalPrice()}</span>
        </div>
        <button className="checkout-btn" onClick={handleCheckout}>
          CHECKOUT
        </button>
      </>
    ) : (
      <button className="checkout-btn" onClick={() => navigate('/bestsellers')}>
        DISCOVER BESTSELLERS
      </button>
    )
  );

  return (
    <div className="cart-overlay">
      <div className="cart-modal">
        {cartHeader()}
        <div className="cart-items">
          {generateCartItems(cartItems)}
        </div>
        <div className="cart-footer">
          {cartFooter()}
        </div>
      </div>
    </div>
  );
};

export default Cart;