import React from 'react';
import { Link } from 'react-router-dom';
import { useNotionArticles } from '../../../../hooks/useNotionArticles';
import LatestTrendTitle from '../../../../assets/images/LatestTrendTitle.png';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import './style.scss';

export const Hero: React.FC = () => {
  const { data: articles, isLoading, error } = useNotionArticles();

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error loading articles</div>;

  const featuredArticles = articles?.slice(0, 2); // Get the first two articles

  return (
    <div className="hero">
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1 }}>
        <HomepageNavbar />
      </div>
      <img src={LatestTrendTitle} alt="Latest Trend" className="hero__title" />
      {featuredArticles?.map((article, index) => (
        <Link to={`/feed/article/${article.id}`} key={article.id} className={`hero__article hero__article--${index + 1}`}>
          <div className="hero__article-background" style={{ backgroundImage: `url(${article.properties.Image.files[0]?.file.url})` }} />
          <div className="hero__article-content">
            <span className="hero__category">
              {article?.dossierNames?.length ?? 0 > 0 ? (
                article?.dossierNames?.map((name, index, array) => (
                  <React.Fragment key={index}>
                    <span className="dossierName">
                      {name.toUpperCase()}
                    </span>
                    {index < array.length - 1 && ' | '}
                  </React.Fragment>
                ))
              ) : (
                <span>NO DOSSIERS</span>
              )}
            </span>
            <h2 className="hero__article-title">{article.properties.Title.title[0]?.plain_text}</h2>
          </div>
        </Link>
      ))}
    </div>
  );
};
