const translations = {
    // Global Sections & Headers
    headers: {
        SHIPPING: 'Shipping',
        PAYMENT_METHOD: 'Payment Method',
        SECURE_PAYMENT: 'Secure Payment',
        PACKAGE: 'Package',
        SHIPPING_ADDRESS: 'Shipping Address',
        NEW_ADDRESS: 'New Address',
        EDIT_ADDRESS: 'Edit Address',
    },
    // Payment Related Text
    payment: {
        CREDIT_DEBIT_CARD: 'Credit/Debit Card',
        LOADING_PAYMENT: 'Loading payment system...',
        PAYMENT_ERROR: 'Payment system is not ready. Please try again.',
        PAYMENT_FAILED: 'Payment failed. Please try again.',
    },
    // Order Summary Section
    orderSummary: {
        SUBTOTAL: 'Subtotal',
        SHIPMENT: 'Shipment',
        TAXES: 'Taxes',
        TOTAL: 'Total',
        DELIVERY_BETWEEN: 'Delivery between',
        ITEMS_COUNT: 'items',
    },
    // Form Fields & Validation
    form: {
        fields: {
            addressName: '',
            firstName: '',
            lastName: '',
            phone: '',
            street: '',
            streetNumber: '',
            postalCode: '',
            city: '',
            country: '',
            email: '',
        },
        labels: {
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            PHONE: 'Phone',
            STREET: 'Street',
            STREET_NUMBER: 'Street Number',
            POSTAL_CODE: 'Postal Code',
            CITY: 'City',
            COUNTRY: 'Country',
        },
        validation: {
            REQUIRED_FIELD: 'This field is required',
            INVALID_EMAIL: 'Invalid email format',
            INVALID_PHONE: 'Invalid phone number',
            INVALID_POSTAL: 'Invalid postal code',
        },
    },

    // Messages & Alerts
    messages: {
        NO_ADDRESS_SELECTED: 'No address selected',
        NO_ITEMS_IN_CART: 'No items in cart',
        PRODUCT_NOT_AVAILABLE: 'Product not available',
        ADDRESS_REQUIRED: 'Please select a shipping address',
        INVALID_FORM: 'Please fill in all required fields',
    },

    // Buttons & Actions
    buttons: {
        CONFIRM_ORDER: 'Confirm Order',
        SAVE_CHANGES: 'Save Changes',
        DELETE: 'Delete',
        CANCEL: 'Cancel',
        ADD_NEW: 'Add New Address',
        BACK: '< Back',
        VIEW_CART: 'View Cart',
        CHECKOUT: 'Checkout',
    },

    // Currency & Units
    currency: {
        SYMBOL: '€',
        ZERO_AMOUNT: '€0.00',
    },
    units: {
        ML: 'ml',
    },

    // Navigation
    navigation: {
        mainNav: {
            SHOP: 'Shop',
            BRANDS: 'Brands',
            ABOUT: 'About',
            CONTACT: 'Contact',
            SEARCH: 'Search',
            LOGIN: 'Login',
            CART: 'Cart',
        },
        shopDropdown: {
            BESTSELLERS: 'Bestsellers',
            NEW_ARRIVALS: 'New Arrivals',
            ALL_PRODUCTS: 'All Products',
            SHOP_BY_BRAND: 'Shop by Brand',
        },
        userMenu: {
            MY_ACCOUNT: 'My Account',
            MY_ORDERS: 'My Orders',
            MY_ADDRESSES: 'My Addresses',
            LOGOUT: 'Logout',
        },
        cartPopup: {
            YOUR_CART: 'Your Cart',
            EMPTY_CART: 'Your cart is empty',
        },
        secondaryNav: {
            SHIPPING_INFO: 'Shipping Info',
            RETURNS: 'Returns',
            PRIVACY_POLICY: 'Privacy Policy',
            TERMS: 'Terms & Conditions',
        },
    },

    // Product Display
    product: {
        fallbacks: {
            UNTITLED_BRAND: 'Untitled Brand',
            UNTITLED_PRODUCT: 'Untitled Product',
            PRODUCT_TITLE_NA: 'Product title not available',
            PRICE_NA: 'Price not available',
        },
        labels: {
            CHOOSE_SIZE: 'Choose a Size',
        },
        risks: {
            headers: {
                CANCER_RISK: 'Cancer RISK',
                IMMUNOTOXICITY: 'Immunotoxicity',
            },
            descriptions: {
                NO_RISKED: '0 risked ingredients',
                RISKED_COUNT: '{count} risked ingredients',
            },
            levels: {
                HIGH: 'HIGH',
                MODERATE: 'MODERATE',
                LOW: 'LOW',
                NO_RISK: 'NO RISK',
            },
        },
        ingredients: {
            CHOOSE_SIZE: 'CHOOSE A SIZE',
            UNKNOWN: 'Unknown',
        },
    },

    // Articles Section
    articles: {
        errors: {
            NO_ARTICLES: 'No articles found.',
        },
        buttons: {
            READ_MORE: 'Read More',
            SEE_ALL: 'See All Articles',
        },
    },

    // Footer
    footer: {
        NEWSLETTER_HEADER: 'Stay updated with Oli news & updates',
        REGISTER_NEWSLETTER: 'Register for Newsletter',
        SOCIAL_LINKS: {
            INSTAGRAM: 'Instagram',
            LINKEDIN: 'LinkedIn',
            TWITTER: 'Twitter',
        },
        LEGAL: {
            COPYRIGHT: '@all rights reserved OLI',
            TERMS: 'Terms of Use',
            PRIVACY: 'Privacy Policy',
        },
    },

    // Risks & Recommendations
    risks: {
        levels: {
            HIGH: 'High',
            MODERATE: 'Moderate',
            LOW: 'Low',
            NO_RISK: 'No Risk',
        },
        types: {
            CANCER: 'Cancer Risk',
            IMMUNOTOXICITY: 'Immunotoxicity',
        },
    },

    // Shop/Bestsellers
    shop: {
        headers: {
            ALL_PRODUCTS: 'ALL PRODUCTS',
            PRODUCTS_COUNT: '{count} products'
        },
        filters: {
            SHOW: 'SHOW',
            HIDE: 'HIDE',
            CLEAR_ALL: 'CLEAR ALL'
        },
        buttons: {
            REMOVE: 'X'
        }
    }
};

export default translations;
