import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import useRandomProducts from 'hooks/randomProducts';
import './style.scss';

interface ProductsCarouselProps {
  onSlideChange: (groupIndex: number) => void;
  carouselId: number;
  brandName?: string;
}

export const ProductsCarousel: React.FC<ProductsCarouselProps> = ({ onSlideChange, carouselId, brandName }) => {
  const { randomProducts } = useRandomProducts(carouselId, brandName);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null); // Track the hovered item index
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    arrows: false,
    variableWidth: false,
    draggable: true,
    touchMove: true,
    slidesToScroll: 1,
    slidesToShow: window.innerWidth < 768 ? 1.2 : 4, // Show 1 slide on mobile
    afterChange: (current: number) => {
      setCurrentSlide(current);
      const groupIndex = Math.floor(current / 3) + 1;
      onSlideChange(groupIndex);
    },
  };

  return (
    <>
      <Slider className="productsCarouselSliderBlock" {...settings}>
        {randomProducts?.map((item, index) => (
          <div key={index} className="productContainer">
            <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div
                className='clickableImage'
                onMouseEnter={() => setHoveredItem(index)} // Set hovered item on mouse enter
                onMouseLeave={() => setHoveredItem(null)} // Reset hovered item on mouse leave
              >
                <img
                  src={hoveredItem === index && item.textureImgUrl ? item.textureImgUrl : item.imgUrl}
                  alt={item.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Changed from 'cover' to 'contain' to avoid cropping
                    objectPosition: 'center',
                    transition: 'opacity 0.3s' // Smooth transition for opacity change

                  }}
                />
              </div>
            </Link>
            <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ padding: 20, textAlign: 'center' }}>
                <p className="productBrand">{item.brand}</p>
                <p className="productTitle">{item.title}</p>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
};
