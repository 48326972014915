import './App.css';
import AppRoutes from './router/Routes';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { config } from './config';

// Currently using the test env of stripe. change it to REACT_APP_STRIPE_PUBLISHABLE_LIVE
// when you want to go live.
const stripePublicKey = config.ENV === 'prod' ? config.REACT_APP_STRIPE_PUBLISHABLE_LIVE : config.REACT_APP_STRIPE_PUBLISHABLE_TEST;
console.log("stripePublicKey ", stripePublicKey);

if (!stripePublicKey) {
  throw new Error("Stripe public key is not defined. Check your environment variables.");
}

const stripePromise = loadStripe(stripePublicKey);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <AppRoutes />
    </Elements>
  );
}

export default App;
