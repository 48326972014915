import api from './apiConfig';
import globalService from 'service/GlobalService';
import { IAnonymousUser, IUser } from 'types/TInterfaces';
import { Dispatch } from 'react';
import { SetStateAction } from 'jotai';
import userService from 'service/UserService';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';

export default class PaymentsApi {

    static getConfig(token: string) {
        return { headers: { 'Authorization': `Bearer ${token}` } }
    }

    static async getToken() {
        const token = await globalService.getAccessToken()
        if (!token) { console.log('error in token'); return }
        return token
    }

    static async checkout(user: IUser | IAnonymousUser, setUser: Dispatch<SetStateAction<IUser | null>>, orderData: any) {
        try {
            let token: string | undefined;
            let isAnonymous = false;
            const isAnonymousUser = userService.isAnonymousUser(user);

            if (isAnonymousUser) {
                isAnonymous = true;
            } else {
                token = await this.getToken();

                if (!token) {
                    console.error('Token is missing, cannot proceed with API call.');
                    return { success: false, message: 'Authorization token is missing.' };
                }
            }

            const config = token ? this.getConfig(token) : {};
            const result = await api.post(`/payments/intents`, { orderData, isAnonymous }, config);
            if (result.status === 200) {
                const clientSecret = result.data.paymentIntent; // Retrieve client_secret from response data
                return { success: true, message: 'Checkout successful', clientSecret };
            } else {
                const errorMsg = 'Failed to checkout: ' + (result.data.message || 'Unknown error');
                console.error(errorMsg);
                return { success: false, message: errorMsg };
            }
        } catch (error: any) {
            console.error('Checkout error:', error.message || error);
            return { success: false, message: 'Failed to checkout.' };
        }
    }

    static async updateOrderStatus(orderId: string, status: string) {
        try {
            const response = await api.post(`/payments/updateorderstatus`, { orderId, status });
            return response.data

        } catch (error) {
            throw error
        }

    }

    static async confirmStripePayment(
        stripe: Stripe,
        cardElement: StripeCardElement,
        clientSecret: string,
        billingDetails: { name: string }
    ) {
        try {
            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: billingDetails,
                },
            });

            return { error, paymentIntent };
        } catch (error) {
            console.error('Error confirming payment:', error);
            throw error;
        }
    }
}