import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import useRandomProducts from 'hooks/randomProducts';
import { ShopHeadSection } from 'components/shopPage/ShopHeadSection/ShopHeadSection';
import { PopularCategories } from 'components/shopPage/PopularCategories/PopularCategories';
import { LargePhotoSection } from 'components/shopPage/LargePhotoSection/LargePhotoSection';
import { BestSellersCarousel } from 'shared/elements/BestSellersCarousel/BestSellersCarousel';
import { ValentineDays } from 'components/shopPage/ValentineDays/ValentineDays';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import Dot from 'assets/icons/blackDot.svg';
import { useState } from 'react';
import { BestSellersBrandSection } from 'components/homepage/BestSellersSection/BestSellersBrandSection';
import { BestSellersSection } from 'components/homepage/BestSellersSection/BestSellersSection';

export const ShopPage = () => {
  const navigate = useNavigate();
  const { randomProducts } = useRandomProducts(6);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  return (
    <>
      <ShopHeadSection />
      <PopularCategories />
      <div className="recommendedBlock">
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <div className="flexContainer">
              <img src={Dot} alt="dot" className="dotIcon" />
              <p className="recTextMain">Selected By olis lab</p>
            </div>
          </div>
          <p className="discover">Discover the world of Oli for a perfect routine</p>
          <button className="APblackButton" style={{ cursor: 'pointer' }} onClick={() => navigate('/bestsellers')}>
            ALL PRODUCTS
          </button>
        </div>

        <div className="cardsContainer">
          {
            randomProducts?.map((item, index) => {
              return (
                <Link to={`/product/${item._id}`} className="productContainer" key={index}
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}>
                  <div>
                    <p className="recText">Just Dropped</p>
                  </div>
                  <div className="productImage" style={{
                    backgroundImage: `url(${hoveredItem === index && item.textureImgUrl ? item.textureImgUrl : item.imgUrl})`
                  }} />
                  <div style={{ padding: 20 }}>
                    <p className="productBrand">{item.brand}</p>
                    <p className="productTitle">{item.title}</p>
                  </div>
                </Link>
              )
            })}
        </div>
      </div>
      <LargePhotoSection />
      <BestSellersSection />
      <ValentineDays />
      <FooterSection />
    </>
  );
};
