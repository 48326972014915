import React, { useRef } from 'react';
import Slider from 'react-slick';
import './style.scss';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { StepIndicator } from 'shared/Indicators/StepIndicator/StepIndicator';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import { useNavigate } from 'react-router-dom';
import { ProductLoader } from '../../../pages/ProductDetails/ProductLoader';

export const ArticlesCarousel: React.FC = () => {
  const sliderRef = useRef<Slider | null>(null);
  const navigate = useNavigate();
  const { data: articles, isLoading, error } = useNotionArticles();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: '0px',
    swipeToSlide: true,
    arrows: false,
    initialSlide: 1,
    variableWidth: true,
    draggable: true,
    touchMove: true,
  };

  const handleClick = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const handleReadMore = (articleId: string) => {
    window.open(`/feed/article/${articleId}`, '_blank');
  };

  if (isLoading) {
    return null;
  }

  if (error) {
    console.error('failed to load carousel:', error);
    return null;
  }

  if (!articles || articles.length === 0) {
    return <div>No articles found.</div>;
  }

  return (
    <div className="ArticlesCarouselContainer">
      <Slider ref={sliderRef} className="ArticlesSliderBlock" {...settings}>
        {articles.map((item, index) => (
          <div className="artilceSliderItemContainer" key={item.id} style={{ backgroundImage: `url(${item.properties.Image.files[0]?.file.url})` }} onClick={() => handleClick(index)}>
            <div
              style={{
                backgroundImage: `url(${item.properties.Image.files[0]?.file.url})`,
                width: 622,
                height: 550,
                borderRadius: 20,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                paddingBottom: 30,
                alignItems: 'center',
              }}
            >
              <div className="ArticleCarouselItemReadMore" onClick={(e) => {
                e.stopPropagation();
                handleReadMore(item.id);
              }}>
                {/* <StepIndicator steps={articles.length} activeStep={index} /> */}
                <p className="ArticleCarouselItemTitle">{item.properties.Title.title[0]?.plain_text}</p>
                <p className="ArticleCarouselItemDescription">
                  {item?.dossierNames?.length ?? 0 > 0 ? (
                    item?.dossierNames?.map((name, index, array) => (
                      <React.Fragment key={index}>
                        <span className="dossierName">
                          {name}
                        </span>
                        {index < array.length - 1 && ' | '}
                      </React.Fragment>
                    ))
                  ) : (
                    <span>No Dossiers</span>
                  )}
                </p>
                <DefaultButton
                  buttonColor="lightYellow"
                  text="READ MORE"
                />
              </div>
            </div>
          </div>
        ))}

      </Slider>
    </div>
  );
};
