import { IUser } from "../types/TInterfaces";
import { Dispatch } from "react";
import { SetStateAction } from "react";
import { deleteLocalCart } from 'service/localCartService';

export default class globalService {
  static handleUserCartAfterPayment(
    isAnonymousUser: boolean,
    user: IUser | null,
    setUser: Dispatch<SetStateAction<IUser | null>>
  ) {
    if (!isAnonymousUser) {
      const newUser: IUser = { ...(user as IUser), cart: [] };
      this.saveUser(newUser, setUser);
    } else {
      deleteLocalCart();
    }
  }

  static saveTokens(accessToken: string, refreshToken: string) {
    try {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    } catch (e) {
      console.error('Error saving tokens:', e);
    }
  }


  static saveUserToLocalStorage(user: IUser) {
    try {
      localStorage.setItem('user', JSON.stringify(user));
    } catch (e) {
      console.error('Error saving user:', e);
    }
  }


  static async saveUserAndTokens(
    accessToken: string,
    refreshToken: string,
    user: IUser,
    setUser: (user: IUser | null) => void
  ) {
    try {
      this.saveTokens(accessToken, refreshToken);
      this.saveUserToLocalStorage(user);
      setUser(user);
    } catch (e) {
      console.error('Error saving user and tokens:', e);
    }
  }

  // Load user from localStorage
  static loadUserFromStorage(setUser: (user: IUser | null) => void) {
    try {
      const storedUser = localStorage.getItem('user');
      const accessToken = localStorage.getItem('accessToken');

      if (storedUser && accessToken) {
        const parsedUser: IUser = JSON.parse(storedUser);
        setUser(parsedUser);
        return parsedUser;
      }
      return null;
    } catch (error) {
      console.error('Error loading user from storage:', error);
      return null;
    }
  }


  static async saveUser(user: IUser, setUser: Dispatch<SetStateAction<IUser | null>>) {
    try {
      this.saveUserToLocalStorage(user)
      setUser(user);
    } catch (e) { console.log(e); }
  }


  static getAccessToken() {
    return localStorage.getItem('accessToken');
  }


  static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  static async deleteTokens() {
    try {
      await localStorage.removeItem('accessToken');
      await localStorage.removeItem('refreshToken');
    } catch (e) { console.log(e) }
  }

  static async clearStorage() {
    try {
      this.deleteTokens()
      await localStorage.removeItem('user');
    } catch (e) { console.log(e) }

  }


  static async logOut(setUser: Dispatch<SetStateAction<IUser | null>>) {
    try {
      this.clearStorage()
      setUser(null);
    } catch (e) { console.log(e) }
  }

}

