import './style.scss';
import { useEffect, useState } from 'react';
import HeaderBackgroundImage from 'assets/images/headSectionBackground.jpg';
import MainText from 'assets/textFont/RoutineForAllSkinTypes.svg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import Logo from 'assets/icons/whiteLogo.svg';
import ScrollVector from 'assets/icons/scrollVector.svg';
export const HeadSection = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);  // New state to manage logo visibility

  const handleScroll = () => {
    const logoElement = document.querySelector('.logo-container') as HTMLElement;
    const headerBlock = document.querySelector('.headerBlockHome') as HTMLElement;

    if (logoElement && headerBlock) {
      const headerBottom = headerBlock.getBoundingClientRect().bottom;
      const viewportTop = window.innerHeight;

      // Check if the bottom of the header block is at the top of the viewport
      setHideLogo(headerBottom <= 0);  // Hide logo when bottom of the header reaches the top of the viewport

      // If the logo reaches the top of the viewport, make it sticky
      const logoTop = logoElement.getBoundingClientRect().top;
      setIsSticky(logoTop <= 10);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="headerBlockHome" style={{ backgroundImage: `url(${HeaderBackgroundImage})` }}>
      <HomepageNavbar />
      <div className="headerBlock-content">
        <div className="logo-container">
          <div
            className={`logo ${isSticky ? 'sticky' : ''} ${hideLogo ? 'hidden' : ''}`}
            style={{ backgroundImage: `url(${Logo})` }}
          />
          <p className="headerAdvice">Take better care of yourself</p>
        </div>

        <div className="headerRight">
          <img src={MainText} alt="Routine For All Skin Types" className="mainText" />
        </div>

        <div>
          <p className="headerRight-text">Professional Advices</p>
        </div>
      </div>
      {window.innerWidth < 768 ? (
        <div className="scrollVectorContainer">
          <img src={ScrollVector} alt="Scroll Vector" className="scrollVector" />
          <span className="scrollVectorText">Scroll down</span>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
