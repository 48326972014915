import './style.scss';
import CountBackground from 'assets/icons/cartCountBackground.svg';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import MenuImg1 from 'assets/images/menuDefaultImg1.jpg';
import MenuImg2 from 'assets/images/menuDefaultImg2.jpg';
import WhiteDot from 'assets/icons/whiteDot.svg';
import BlackDot from 'assets/icons/blackDot.svg';
import WhiteLogo from 'assets/icons/whiteLogo.svg';
import { userAtom } from '../../../stores/UserProfile';
import { useAtom } from 'jotai';
import Cart from '../../cart/cart';
import { IProductInCart } from '../../../types/TInterfaces';
import { useLocation } from 'react-router-dom';
import userService from 'service/UserService';

export const SignupNavbar = () => {
  const [user] = useAtom(userAtom);
  const location = useLocation();
  const [menuVisible, setMenuVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const isProfileForm = location.pathname.includes('/spf-form/');
  const { data: articles } = useNotionArticles();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<IProductInCart[]>([]);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };

  const getTotalItems = () => cartItems.reduce((total, item) => total + item.quantity, 0);

  const menuItems = [
    { title: 'Shop +', target: '/shop' },
    { title: 'Bestsellers', target: '/bestsellers' },
    { title: 'Feed', target: '/feed' },
    ...(user && !userService.isAnonymousUser(user) ? [{ title: 'Profile', target: '/profile/skin-resume' }] : []),
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && menuVisible) {
        setMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);

  return (
    <>
      <div className="navbar">
        <div className="navbar-left-logo">
          <Link to="/">
            <img className="navbar-logo" src={WhiteLogo} alt="White Logo" />
          </Link>
          {isProfileForm && (
            <button onClick={() => navigate(-1)} className="back-button-sign">
              &lt; BACK
            </button>
          )}
        </div>

        <div className="navbar-left">
          <span onClick={() => setMenuVisible(!menuVisible)} className="navbar-item">MENU</span>
        </div>
        <nav className="navbar-right">
          {user && (
            <span onClick={toggleCart} className="navbar-item cart-container">
              CART
              <span className="cart-count" style={{ backgroundImage: `url(${CountBackground})` }}>
                {getTotalItems()}
              </span>
            </span>
          )}
        </nav>
      </div>

      <Cart isOpen={cartVisible} onClose={toggleCart} cartItems={cartItems} setCartItems={setCartItems} />

      <div ref={menuRef} className="menuContainer" style={{ display: menuVisible ? 'flex' : 'none', flexDirection: 'column' }}>
        <button onClick={() => setMenuVisible(false)} className="menuContainerCloseButton" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="menuContainerImgDot" style={{ backgroundImage: `url(${BlackDot})`, height: 14, marginRight: 6, width: 13 }} />
          <p className="menuContainerCloseText">CLOSE</p>
        </button>

        <ul>
          {menuItems.map((item, index) => (
            <li className="menuContainerMenuItem" key={index} onClick={() => item.target && navigate(item.target)}>
              {item.title}
            </li>
          ))}
        </ul>

        {articles?.slice(0, 2).map((article) => (
          <Link to={`/feed/article/${article.id}`} key={article.id} style={{ textDecoration: 'none', color: 'white' }}>
            <div className="menuContainerImgBlock" style={{ backgroundImage: `url(${article.properties.Image.files[0]?.file.url})` }}>
              <div className='dossiersContainer'>
                {article?.dossierNames?.length ?? 0 > 0 ? (
                  article?.dossierNames?.map((name, index) => (
                    <div key={index} className="menuContainerImgBlock">
                      <p className="menuContainerImgCategory">
                        <span className="dossierName">
                          {name.toUpperCase()}
                        </span>
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="menuContainerImgCategoryBlock">
                    <p className="menuContainerImgCategory">
                      <span>NO DOSSIERS</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="menuContainerImgHeaderContainer">
                <p className="menuContainerImgHeader">{article.properties.Title.title[0]?.plain_text}</p>
              </div>
            </div>
          </Link>
        ))}

        <div style={{ display: 'flex', marginTop: 6, justifyContent: 'space-between', alignItems: 'center' }}>
          <p className="menuContainerSocialLink">Instagram</p>
          <p className="menuContainerSocialLink">Tiktok</p>
          <p className="menuContainerSocialLink">Linkedin</p>
        </div>
      </div>
    </>
  );
};
