import api from './apiConfig';
import globalService from 'service/GlobalService';
import { IUser, ShippingAddress, IProductInCart } from 'types/TInterfaces';
import { Dispatch } from 'react';
import { SetStateAction } from 'jotai';
import { handleApiError } from '../errorHandler';
import { setLocalStorageItem } from 'utils/localStoragUtil';
import { NavigateFunction } from 'react-router-dom';
import { handleTokenExpiration } from 'utils/tokenHandler';

export default class userApi {

    static getConfig(token: string) {
        return { headers: { 'Authorization': `Bearer ${token}` } }
    }

    static async getToken() {
        const token = await globalService.getAccessToken()
        if (!token) { console.log('error in token'); return }
        return token
    }

    static checkTokenValidity(error: any) {
        if (error.response?.data?.status === 'TOKEN_INVALID') {
            // Token is invalid, user needs to login again
            return {
                success: false,
                message: 'Session expired. Please login again.',
                data: undefined
            };
        }
    }

    static async modifyPassword(setUser: Dispatch<SetStateAction<IUser | null>>, currentPassword: string, newPassword: string) {
        try {
            const token = await this.getToken();
            if (!token) {
                console.error('Token is missing, cannot proceed with API call.');
                return { success: false, message: 'Authorization token is missing.' };
            }

            const passwordData = {
                data: {
                    currentPassword,
                    newPassword,
                },
            };

            const response = await api.post('/user/modyfyPassword', passwordData, this.getConfig(token));

            if (response.status === 200) {
                const { updatedUser, accessToken, refreshToken } = response.data;
                setUser(updatedUser);
                setLocalStorageItem('accessToken', accessToken);
                setLocalStorageItem('refreshToken', refreshToken);
                return { success: true, message: 'Password modified successfully.' };
            } else {
                return { success: false, message: response.data.error || 'Failed to modify password.' };
            }
        } catch (error) {
            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            console.error('Error modifying password:', error);
            return { success: false, message: 'An error occurred while modifying the password.' };
        }
    }

    static async updateUserSPF(setUser: Dispatch<SetStateAction<IUser | null>>, data: any) {
        try {
            const token = await this.getToken();
            if (!token) {
                console.error('Token is missing, cannot proceed with API call.');
                return { success: false, message: 'Authorization token is missing.' };
            }

            const response = await api.post('/user/spf', { data }, this.getConfig(token));
            return { success: response.status === 200, message: response.data };
        } catch (error) {
            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            console.error('Error updating skin profile:', error);
            return { success: false, message: 'An error occurred while updating the skin profile.' };
        }
    }

    static async addProductToCart(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        product: IProductInCart,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const response = await api.post('/user/addtocart', { product }, this.getConfig(token));
            return { success: response.status === 200, message: response.data };

        } catch (error: any) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse = await handleApiError(error, this.addProductToCart.bind(this), setUser, product);
            return retryResponse || { success: false, message: "Failed to add product to cart due to an API error." };
        }
    }

    /**
     * Updates the quantity of a product in the user's cart.
     * 
     * @param setUser - A function to update the state of the user.
     * @param productId - The ID of the product to update.
     * @param count - The new quantity of the product.
     * @returns A promise that resolves to an object indicating the success of the operation and a message.
     */
    static async updateProductInCart(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        productId: string,
        count: number,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const countValues = { [productId]: count };
            const response = await api.post(
                '/user/updatecart',
                { productId, countValues },
                this.getConfig(token)
            );
            return { success: response.status === 200, message: response.data };
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            return { success: false, message: 'Error occurred while updating cart' };
        }
    }

    static async removeProductFromCart(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        productId: string,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const response = await api.post(
                '/user/removefromcart',
                { productId },
                this.getConfig(token)
            );
            return { success: response.status === 200, message: response.data };
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            return { success: false, message: 'Error occurred while removing product from cart' };
        }
    }


    static async addDeliveryAddress(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        address: any,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string; address?: ShippingAddress }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const response = await api.post('/user/addaddress', { address }, this.getConfig(token));
            return response.data;
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }
            const retryResponse = await handleApiError(
                error,
                this.addDeliveryAddress.bind(this),
                setUser,
                address
            );

            return retryResponse || {
                success: false,
                message: "Failed to add delivery address.",
            };
        }
    }
    static async deleteDeliveryAddress(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        addressId: any,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const response = await api.post('/user/deletedeliveryaddress', { addressId }, this.getConfig(token));
            if (response.status === 200) {
                return { success: true, message: 'Delivery address deleted successfully.' };
            } else {
                return { success: false, message: response.data.error || 'Failed to delete delivery address.' };
            }
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse = await handleApiError(
                error,
                this.deleteDeliveryAddress.bind(this),
                setUser,
                addressId
            );

            return retryResponse || { success: false, message: "Failed to delete delivery address due to an API error." };
        }
    }

    static async userLogin(email: string, password: string) {
        try {
            const response = await api.post('/auth/login', {
                email,
                password,
            });
            if (response.status === 200) {
                return { success: true, data: { user: response.data.user, accessToken: response.data.accessToken, refreshToken: response.data.refreshToken } };
            } else {
                return { success: false, message: 'Invalid email or password' };
            }
        } catch (error) {
            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            console.error('Error logging in:', error);
            return { success: false, message: 'An error occurred while logging in.' };
        }
    }

    static async updateDeliveryAddress(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        newAddress: ShippingAddress,
        addressId: string,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string; address?: ShippingAddress }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const response = await api.post(
                '/user/updatedeliveryaddress',
                { addressId, newAddress },
                this.getConfig(token)
            );

            return {
                success: response.status === 200,
                message: response.data.message,
                address: response.data.address
            };
        } catch (error: unknown) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse: { success: boolean; message: string; address?: ShippingAddress } | null = await handleApiError(
                error,
                this.updateDeliveryAddress.bind(this),
                setUser,
                newAddress,
                addressId
            );

            return retryResponse || { success: false, message: "Failed to update delivery address." };
        }
    }


    static async getUserData(
        userId: string,
        setUser: Dispatch<SetStateAction<IUser | null>>
    ): Promise<{ success: boolean; message: string; data?: IUser }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const response = await api.get(`/user-data/data?userId=${userId}`, this.getConfig(token));

            return {
                success: response.status === 200,
                message: response.data.message,
                data: response.data
            };
        } catch (error: unknown) {
            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse = await handleApiError(
                error,
                this.getUserData.bind(this),
                setUser
            );

            return retryResponse || { success: false, message: "Failed to fetch user data." };
        }
    }

    static async updateUser(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        updatedData: Partial<IUser>,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string; user?: IUser }> {
        try {
            const token = await this.getToken();
            if (!token) return { success: false, message: "No token available" };

            const response = await api.post('/user/update-info', { data: updatedData }, this.getConfig(token));

            if (response.status === 200) {
                const updatedUser = response.data.updatedUser;
                setUser(updatedUser);
                return { success: true, message: 'User updated successfully.', user: updatedUser };
            } else {
                return { success: false, message: response.data.error || 'Failed to update user.' };
            }
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }

            const tokenValidity = this.checkTokenValidity(error);
            if (tokenValidity) return tokenValidity;

            console.error('Error updating user:', error);
            return { success: false, message: 'An error occurred while updating the user.' };
        }
    }

}

