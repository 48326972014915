import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNotionActives } from 'hooks/useNotionArticles';
import './style.scss';
import oneActiveBg from '../../../assets/images/OneActiveBg.png';

export const OneActive: React.FC = () => {
    const { activeId } = useParams<{ activeId: string }>();
    const navigate = useNavigate();
    const { data: actives, isLoading, error } = useNotionActives();

    // State to track selected menu items
    const [selectedMenuItems, setSelectedMenuItems] = useState<Set<number>>(new Set());

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading actives</div>;

    const active = actives?.find(a => a.id === activeId);
    console.log(active);
    if (!active) return <div>Active not found</div>;

    // Handle menu item click
    const handleMenuClick = (index: number) => {
        const newSelectedMenuItems = new Set(selectedMenuItems);
        if (newSelectedMenuItems.has(index)) {
            newSelectedMenuItems.delete(index); // Deselect if already selected
        } else {
            newSelectedMenuItems.add(index); // Select if not already selected
        }
        setSelectedMenuItems(newSelectedMenuItems);
    };

    return (
        <div className="article-detail-container">
            <button className="back-button" onClick={() => navigate(-1)} aria-label="Go back">
                &lt; BACK
            </button>
            <div className="article-image-section">
                <img
                    src={oneActiveBg}
                    alt={active.properties.Name.title[0]?.plain_text}
                    className="article-image"
                />
                <div className="article-image-overlay"></div>
                <div className="article-meta">
                    <div className="article-header">
                        <h1 className="article-title">
                            {active.properties.Name.title[0]?.plain_text}
                        </h1>
                        <div className="article-category">
                            {active.properties.Functions.multi_select.map((func: any) => (
                                <span key={func.id} className="function-badge">
                                    {func.name}
                                </span>
                            ))}
                            {active.properties.Synonyms.relation && active.properties.Synonyms.relation.length > 0 && (
                                <>
                                    {active.properties.Synonyms.relation.map((synonym: any) => {
                                        const synonymActive = actives?.find(a => a.id === synonym.id);
                                        return synonymActive?.properties.Functions.multi_select.map((func: any) => (
                                            <span key={func.id} className="function-badge">
                                                {func.name}
                                            </span>
                                        ));
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="article-content-section">
                {/* <div className="article-actions">
                    <button className="share-button">SHARE</button>
                    <button className="save-button">+ SAVE</button>
                </div> */}
                <div className="article-middle-text">
                    <h2>
                        Bakuchiol is a plant-derived ingredient found in the leaves and seeds of Psoralea corylifolia.
                        It is a powerful antioxidant, reducing sun-induced depigmentation and soothing the skin.
                        It can also reduce the appearance of fine lines and wrinkles, which explains its presence
                        in skin care products. Bakuchiol has its origins in Chinese medicine, and recent studies
                        show its benefits for topical application.
                    </h2>
                </div>

                <div className="menu" style={{ position: 'relative', bottom: '20px', left: '20px' }}>
                    {['skin type', 'benefits', 'how to use', 'add to my routine', 'recommendations', 'combinations', 'products'].map((item, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <div className="menu-item" onClick={() => handleMenuClick(index)}>
                                <span className="menu-number">{String(index + 1).padStart(2, '0')}</span> {item} <span className="menu-plus">{selectedMenuItems.has(index) ? '-' : '+'}</span>
                            </div>

                            {selectedMenuItems.has(index) && (
                                <div className="dummy-section" style={{ marginTop: '10px' }}>
                                    {/* <h3>Studies show that Bakuchiol possesses a range of anti-ageing characteristics. It can reduce the appearance of fine lines and wrinkles, help restore skin firmness, refine texture and even even out skin tone. Its soothing properties make it particularly suitable for sensitive skin.</h3> */}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
