import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import { SignupNavbar } from '../../../components/navbars/SignupNavbar/SignupNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import '../style.scss';

// Generate the days, months, and years for dropdown options
const days = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0')); // ['01', '02', ..., '31']
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']; // MM format
const years = Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - i).toString()); // Past 100 years

export const SPF_Age: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();

  const [day, setDay] = useState<string | undefined>();
  const [month, setMonth] = useState<string | undefined>();
  const [year, setYear] = useState<string | undefined>();

  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => setDay(e.target.value);
  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => setMonth(e.target.value);
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => setYear(e.target.value);

  const isNextDisabled = !(day && month && year);

  const goToNext = () => {
    if (!isNextDisabled) {
      const selectedDate = `${year}-${month}-${day}`;
      setSkinProfile({ ...skinProfile, age: selectedDate });
      navigate('/spf-form/spf-concerns');
    }
  };

  useEffect(() => {
    console.log('Current skinProfileAtom state:', skinProfile);
    console.log('user', user);

    // Initialize day, month, and year if user.SPF.age exists
    if (user?.SPF?.age) {
      const date = new Date(user.SPF.age);
      const savedYear = date.getUTCFullYear().toString();
      const savedMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
      const savedDay = date.getUTCDate().toString().padStart(2, '0');

      setYear(savedYear);
      setMonth(savedMonth);
      setDay(savedDay);
    }
  }, [user, skinProfile]);

  return (
    <div className="headerBlockFormsSkin" style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <SignupNavbar />
      <div className="form-skin-profile-container">
        <div className="form-container-skin">
          <StepIndicator currentStep={2} />
          <h1 className="age-title">HOW OLD ARE YOU?</h1>
          <h5 className="age-subtitle">Time is beautiful and unique, your age allows us to best meet your needs.</h5>

          <div className="dropdowns-container">
            <select value={day} onChange={handleDayChange} className="dropdown">
              <option value="">JJ</option>
              {days.map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </select>
            <select value={month} onChange={handleMonthChange} className="dropdown">
              <option value="">MM</option>
              {months.map((m) => (
                <option key={m} value={m}>
                  {m}
                </option>
              ))}
            </select>
            <select value={year} onChange={handleYearChange} className="dropdown">
              <option value="">YYYY</option>
              {years.map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </select>
          </div>

          <button className="next-button-form" disabled={isNextDisabled} onClick={goToNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
