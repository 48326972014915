import React from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import blackLogo from '../../../assets/icons/blackLogo.svg';
import { SignupNavbar } from '../../../components/navbars/SignupNavbar/SignupNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import profileSelectionIcon from '../../../assets/images/profileSelectionIcon.png';
import '../style.scss';

export const SPF_WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useAtom(userAtom);

  const goToForm = () => {
    navigate('/spf-form/spf-gender');
  };

  const maybeLater = () => {
    navigate('/');
  };

  return (
    <div className="headerBlockFormsSkin" style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <SignupNavbar />
      <div className="form-skin-profile-container">
        <div className="form-container-skin-welcome">
          <img className="group" alt="Group" src={profileSelectionIcon} />
          <h1 className="title-welcome">
            WELCOME TO OLI, <br /> {user?.name?.toUpperCase() || ''}
          </h1>
          <p className="subtitle-welcome">YOUR ACCOUNT HAS BEEN CREATED. NOW IT’S TIME TO ANSWER TO A FEW QUESTIONS IN ORDER TO CREATE YOUR PERFECT ROUTINE.</p>
          <button className="next-button-form" onClick={goToForm}>
            Create my profile
          </button>
          <button className="maybe-later-button" onClick={maybeLater}>
            MAYBE LATER
          </button>
        </div>
      </div>
    </div>
  );
};
