import React from 'react';
import './style.scss';
import FooterBack from 'assets/images/homePageFooterBackground.jpg';
import WhiteLogo from 'assets/icons/whiteLogo.svg';
import AppleLogo from 'assets/icons/appleLogo.svg'
import Dot from 'assets/icons/blackDot.svg'
import { useNavigate } from 'react-router-dom';

export const FooterSection: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="headerBlock footerContainer" style={{ backgroundImage: `url(${FooterBack})` }}>
      <div
        className="footerMenu"
        style={{
          backgroundColor: '#F7F6E6',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          borderRadius: 12,
          paddingRight: 50,
          paddingLeft: 50,
          paddingTop: 50,
          paddingBottom: 20,
        }}
      >
        <div className='topContainer'>
          <p className="footerHeader">Take better care of yourself with Oli news & updates</p>
          <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <div style={{ backgroundImage: `url(${Dot})`, height: 8.34, width: 9.02, objectFit: 'contain', marginRight: 10 }} />
            <p className="regNews">register Newsletter</p>
          </div>

          <div className="storesButtonsBlock" style={{ marginTop: 63, marginBottom: 40 }}>
            <button style={{ marginRight: 10, marginBottom: 20, position: 'relative' }}>
              <div style={{ backgroundImage: `url(${AppleLogo})`, height: 14, position: 'absolute', left: 20, width: 12 }} />
              <p>App store</p>
            </button>

            <button style={{ marginRight: 10, marginBottom: 20, position: 'relative' }}>
              <div style={{ backgroundImage: `url(${AppleLogo})`, height: 14, position: 'absolute', left: 20, width: 12 }} />
              <p>Google play</p>
            </button>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ marginRight: 125, marginBottom: 30 }}>
              <p className="footerMenuHeader">SHOP</p>
              <ul>
                <li onClick={() => navigate('/shop')} className="footerMenuItem">shop</li>
                <li onClick={() => navigate('/bestsellers')} className="footerMenuItem">BestSellers</li>
                <li className="footerMenuItem">News Drops</li>
              </ul>
            </div>

            <div style={{ marginRight: 93, marginBottom: 30 }}>
              <p className="footerMenuHeader">OTHER</p>
              <ul>
                <li onClick={() => navigate('/feed')} className="footerMenuItem">Feed</li>
                <li className="footerMenuItem">Partners</li>
                <li className="footerMenuItem">Contact</li>
              </ul>
            </div>

            <div style={{ marginRight: 30, marginBottom: 30 }}>
              <p className="footerMenuHeader">SOCIAL</p>
              <ul>
                <li className="footerMenuItem">Instagram</li>
                <li className="footerMenuItem">Linkedin</li>
                <li className="footerMenuItem">Twitter</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="policyBlock"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            marginTop: 200,
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <p style={{ marginRight: 10 }} className="allRights">
            @allrightreserved OLI
          </p>
          <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
            <p className="policyBtn" style={{ marginRight: 40, marginTop: 20 }}>
              Terms of use
            </p>
            <p style={{ marginTop: 10 }} className="policyBtn">
              Privacy policy
            </p>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', marginBottom: 170, flexDirection: 'column', alignItems: 'center' }}>
        <div className="footerLogo" style={{ backgroundImage: `url(${WhiteLogo})` }} />
        <p className="footerAdvice">Take better care of yourself</p>
      </div>
    </div>
  );
};
