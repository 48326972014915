// src/routes/Routes.tsx
import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Routes, Route } from 'react-router-dom';
import { Homepage } from '../pages/homepage/homepage';
import { ShopPage } from 'pages/ShopPage/ShopPage';
import { SignUpPage } from 'pages/SignUpPage/SignUpPage';
import { LogInPage } from 'pages/LogInPage/LogInPage';
import { CheckoutPage } from 'pages/checkout/checkout';
import { ShopBestsellers } from 'pages/ShopBestsellers/ShopBestsellers';
import { ProductDetail } from 'pages/ProductDetails/ProductDetails';
import { Feed } from 'pages/Feed/Feed';
import { ArticleDetail } from 'pages/Feed/ArticleDetail/ArticleDetail';
import { DossiersList } from 'pages/Feed/DossiersList/DossiersList';
import { ActivesList } from 'pages/Feed/ActivesList/ActivesList';
import { OneActive } from 'pages/Feed/OneActive/OneActive';
import { SkinTypeFormStack } from '../pages/Form/Form';
import { Layout } from 'pages/ProfilePage/layout/layout';
import { ProfileSettings } from 'pages/ProfilePage/ProfileSettings/ProfileSettings';
import { AddressForm } from 'pages/ProfilePage/ProfileSettings/components/AddressForm/AddressForm';
import { ChangePassword } from 'pages/ProfilePage/ProfileSettings/components/ChangePassword/ChangePassword';
import { SkinResume } from 'pages/ProfilePage/SkinResume/SkinResume';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import globalService from '../service/GlobalService';
import { Brands } from 'components/brands/brands';
import { userAtom } from 'stores/UserProfile';
import ScrollToTop from 'components/navbars/utils/ScrollToTop';
import { TermsOfUse } from 'pages/ProfilePage/ProfileSettings/components/TermsOfUse/TermsOfUse';
import { AccountForm } from 'pages/ProfilePage/ProfileSettings/components/AccountForm/AccountForm';
import ErrorPage from 'pages/ErrorPage/ErrorPage';

const AppRoutes: React.FC = () => {

  const [, setUser] = useAtom(userAtom);

  useEffect(() => {
    globalService.loadUserFromStorage(setUser);
  }, []);

  return (
    <>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/bestsellers" element={<ShopBestsellers />} />
        <Route path="/brand/:brandName" element={<Brands />} />
        <Route path="/product/:productId" element={<ProductDetail />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/*" element={<ErrorPage message="Page not found" />} />
        <Route path="/feed/article/:articleId" element={<ArticleDetail />} />
        <Route path="/feed/dossier/:dossierId" element={<DossiersList />} />
        <Route path="/feed/actives" element={<ActivesList />} />
        <Route path="/feed/actives/:activeId" element={<OneActive />} />
        <Route path="/spf-form/*" element={<SkinTypeFormStack />} />
        <Route path="/profile" element={<Layout />} />
        <Route path="/profile/skin-resume" element={<SkinResume />} />
        <Route path="/profile/settings" element={<ProfileSettings />} />
        <Route path="/profile/settings/add-address" element={<AddressForm />} />
        <Route path="/profile/settings/edit-account" element={<AccountForm />} />
        <Route path="/profile/settings/add-address/:addressId" element={<AddressForm />} />
        <Route path="/profile/settings/change-password" element={<ChangePassword />} />
        <Route path="/profile/settings/terms-of-use" element={<TermsOfUse />} />
        <Route path="/justdropped" element={<ErrorPage message='Coming soon! This feature is currently in development and will be available shortly' />} />
        <Route path="/recommendations" element={<ErrorPage message="Coming soon! This feature is currently in development and will be available shortly" />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
