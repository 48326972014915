import { IProductInCart } from '../types/TInterfaces';
import { setLocalStorageItem, getLocalStorageItem, clearLocalStorage } from '../utils/localStoragUtil';

const LOCAL_CART_KEY = 'localCart';

/**
 * Loads the local cart from localStorage.
 * @returns {IProductInCart[]} The local cart items.
 */
export const loadLocalCart = (): IProductInCart[] => {
    try {
        return getLocalStorageItem(LOCAL_CART_KEY) || [];
    } catch (error) {
        console.error('Error loading local cart:', error);
        return [];
    }
};

/**
 * Saves the local cart to localStorage.
 * @param {IProductInCart[]} cart - The cart items to save.
 */
export const saveLocalCart = (cart: IProductInCart[]): void => {
    try {
        setLocalStorageItem(LOCAL_CART_KEY, cart);
    } catch (error) {
        console.error('Error saving local cart:', error);
    }
};

/**
 * Adds a product to the local cart.
 * @param {IProductInCart} product - The product to add to the cart.
 * @returns {IProductInCart[]} The updated cart.
 */
export const addToLocalCart = (product: IProductInCart): IProductInCart[] => {
    const cart = loadLocalCart();
    const existingProductIndex = cart.findIndex(item => item.product._id === product.product._id);

    if (existingProductIndex !== -1) {
        cart[existingProductIndex].quantity += 1;
    } else {
        cart.push({ ...product, quantity: 1 });
    }

    saveLocalCart(cart);
    return cart;
};

/**
 * Removes a product from the local cart.
 * @param {string} productId - The ID of the product to remove.
 * @returns {IProductInCart[]} The updated cart.
 */
export const removeFromLocalCart = (productId: string): IProductInCart[] => {
    const cart = loadLocalCart();
    const updatedCart = cart.filter(item => item.product._id !== productId);
    saveLocalCart(updatedCart);
    return updatedCart;
};

/**
 * Updates the quantity of a product in the local cart.
 * @param {string} productId - The ID of the product to update.
 * @param {number} newQuantity - The new quantity of the product.
 * @returns {IProductInCart[]} The updated cart.
 */
export const updateLocalCartItemQuantity = (productId: string, newQuantity: number): IProductInCart[] => {
    const cart = loadLocalCart();
    const updatedCart = cart.map(item =>
        item.product._id === productId ? { ...item, quantity: newQuantity } : item
    );
    saveLocalCart(updatedCart);
    return updatedCart;
};

export const deleteLocalCart = (): void => {
    try {
        localStorage.removeItem(LOCAL_CART_KEY);
    } catch (error) {
        console.error('Error deleting local cart:', error);
    }
};