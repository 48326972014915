import React, { useRef, useState } from 'react';
import './style.scss';
import CardBackground1 from 'assets/images/PopularCard1.png';
import CardBackground2 from 'assets/images/PopularCard2.png';
import CardBackground3 from 'assets/images/PopularCard3.png';
import november from 'assets/textFont/november_edit.svg';
import JustDropped from 'assets/textFont/just_dropped.svg';
import OurSelection from 'assets/textFont/our_selection.svg';
import { useNavigate } from 'react-router-dom';

export const PopularCategories: React.FC = () => {
  const cardsContainerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate(); // useNavigate hook to navigate on click

  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    const container = cardsContainerRef.current;
    if (container) {
      setIsDown(true);
      setStartX(e.pageX - container.offsetLeft);
      setScrollLeft(container.scrollLeft);
    }
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const container = cardsContainerRef.current;
    if (isDown && container) {
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2;
      container.scrollLeft = scrollLeft - walk;
    }
  };

  const cards = [
    {
      background: CardBackground1,
      title: november,
      text: 'Explore our',
      route: '/bestsellers',
      pageTitle: 'NOVEMBER EDIT',
      headerBackground: CardBackground1
    },
    {
      background: CardBackground2,
      title: JustDropped,
      text: 'Fresh and news',
      route: '/bestsellers',
      pageTitle: 'JUST DROPPED',
      headerBackground: CardBackground2
    },
    {
      background: CardBackground3,
      title: OurSelection,
      text: 'Discover',
      route: '/bestsellers',
      pageTitle: 'OUR SELECTION',
      headerBackground: CardBackground3
    },
  ];

  return (
    <div className="PopularCategoriesContainer">
      <div className="PopularCategoriesHeader">
        <p className='PopularCategoriesHeaderText'>Popular categories</p>
      </div>
      <div
        className='PopularCategoriesCardsContainer'
        ref={cardsContainerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {cards.map((item, index) => (
          <div
            key={index}
            className='PopularCategoriesCard'
            style={{ backgroundImage: `url(${item.background})`, cursor: 'pointer' }}
            onClick={() => {
              if (item.route) {
                navigate(item.route, {
                  state: {
                    customTitle: item.pageTitle,
                    headerBackground: item.background
                  }
                });
              }
            }}
          >
            <p className='PopularCategoriesCardText'>{item.text}</p>
            <div className='PopularCategoriesCardTitle' style={{ backgroundImage: `url(${item.title})` }} />
          </div>
        ))}
      </div>
    </div>
  );
};
