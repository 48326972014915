import api from './apiConfig';
import { getErrorMessage } from 'service/serviceFunctions';
import { ShopProduct } from '../../types/ShopInterfaces';


export default class productsApi {

  static async getRandomProducts(quantity?: number, subcategory?: string): Promise<{ success: boolean, data?: ShopProduct[], message?: any }> {
    try {
      let url = `/products/random/${quantity}`;
      if (subcategory) { url += `/${subcategory}` }
      const response = await api.get(url);
      if (response.data.products.length === 0) {
        return { success: false, message: "No products found" };
      }
      return { success: true, data: response.data.products };
    } catch (error) {
      console.error('API JS error while fetching random products:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductById(productId: string): Promise<{ success: boolean, data?: ShopProduct, message?: string }> {
    try {
      const response = await api.get(`/products/${productId}`);
      if (!response.data.product) {
        return { success: false, message: "Product not found" };
      }
      return { success: true, data: response.data.product };
    } catch (error) {
      console.error('API JS error while fetching product by ID:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getAllProducts(subcategory?: string): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      let url = `/products/category`;

      if (subcategory) { url += `/${subcategory}` }
      else { url += `/undefined` }

      const response = await api.get(url);
      return { success: true, data: response.data.products };
    } catch (error) {
      console.error('API JS error while fetching all products:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductsByBrand(brandName: string): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      const response = await api.get(`/products/brand/${brandName}`);
      return { success: true, data: response.data.products };
    } catch (error) {
      console.error('API JS error while fetching products by brand:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async checkProductAvailability(sku: string): Promise<{ success: boolean, available: boolean, message?: string }> {
    try {
      if (!sku) {
        throw new Error("Product ID is required");
      }

      const payload = {
        product: sku
      };

      const response = await api.post(`/products/checkProductAvailability`, payload);

      const isAvailable = response.data.available > 0;
      return { success: true, available: isAvailable };
    } catch (error) {
      console.error('Error checking product availability:', getErrorMessage(error));
      return { success: false, available: false, message: getErrorMessage(error) };
    }
  }
}