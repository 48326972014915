import React from 'react';
import { Link } from 'react-router-dom';
import { config } from '../../../../../config'
import './style.scss';

export const ContactBox: React.FC = () => {
    const handleEmail = (subject: string) => {
        const email = config.REACT_APP_SUPPORT_EMAIL;
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
        window.location.href = mailtoLink;
    };

    return (
        <div className="contact-box">
            <div className="content-container">
                <div className="contact-item">
                    <h5 className="item-title">TERMS & CONDITIONS</h5>
                    <Link to="terms-of-use" className="btn-action">
                        READ
                    </Link>
                </div>
                <div className="divider-in-box" />

                <div className="contact-item">
                    <h5 className="item-title">HELP & SUPPORT</h5>
                    <button className="btn-action" onClick={() => handleEmail("Help & Support")}>
                        CONTACT US
                    </button>
                </div>
                <div className="divider-in-box" />

                <div className="contact-item">
                    <h5 className="item-title">REPORT A BUG</h5>
                    <button className="btn-action" onClick={() => handleEmail("Bug Report")}>
                        CONTACT US
                    </button>
                </div>
            </div>
        </div>
    );
};
