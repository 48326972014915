import React, { useState } from 'react';
import './style.scss';
import { ProductsCarousel } from 'components/carousels/ProductsCarousel';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { useNavigate, useLocation } from 'react-router-dom';

export const BestSellersSection: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeGroup, setActiveGroup] = useState(1);
  const isShopPage = location.pathname.includes('/shop');

  const handleSlideChange = (groupIndex: number) => {
    setActiveGroup(groupIndex);
  };

  const renderIndicator = (
    <div className={isShopPage ? "indicatorContainer-shopPage" : "indicatorContainer"}>
      <ScrollNumberIndicator count={3} activeNumber={activeGroup} fontSize={12} color="black" />
    </div>
  );

  const renderShopButton = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <DefaultButton marginBottom={6} marginTop={40} text="Shop products" onClick={() => navigate('/shop')} />
    </div>
  );

  return (
    <div
      className="bestsellersContainer"
      style={{
        backgroundColor: '#F7F6E7',
        padding: `${isShopPage ? '10px' : '100px'} 10px 20px 10px`
      }}
    >
      <div className="BSheaderBlock"
        style={{
          marginBottom: `${isShopPage ? '10px' : '120px'}`
        }}
      >
        <p className={isShopPage ? "header-shopPage" : "header"}>BestSellers</p>
        {!isShopPage &&
          (window.innerWidth > 768 ? (
            <p className="description">
              Discover the world of Oli for a perfect routine. Our
              <br />
              products are carefully selected to best meet the needs
              <br />
              of your skin.
            </p>
          ) : (
            <p className="description">
              Discover the world of Oli for a perfect routine. Our products are carefully selected to best meet the needs of your skin.
            </p>
          ))
        }
      </div>
      {!isShopPage && renderIndicator}
      <ProductsCarousel onSlideChange={handleSlideChange} carouselId={12} />
      {isShopPage ? renderIndicator : renderShopButton}
    </div>
  );
};
