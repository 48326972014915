import './style.scss';
import { Link } from 'react-router-dom';
import useRandomProducts from 'hooks/randomProducts';

export const ProductAdvice = () => {
  const { randomProducts, loading } = useRandomProducts(1);

  if (loading || !randomProducts || randomProducts.length === 0) {
    return null;
  }

  return (
    <Link to={`/product/${randomProducts[0]._id}`} className="floatProductAdviceContainer">
      <div className="floatProductAdviceImage" style={{ backgroundImage: `url(${randomProducts[0].imgUrl})` }} />
      <div style={{ marginLeft: 17 }}>
        <p className="floatProductAdviceBrand">{randomProducts[0].brand}</p>
        <p className="floatProductAdviceTitle">{randomProducts[0].title}</p>
      </div>
    </Link>
  );
};
