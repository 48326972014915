import './style.scss';
import ImgBackground from 'assets/images/valentineDaysBack.jpg';
import PerfectVD from 'assets/textFont/PerfectVD.svg';
import useRandomProducts from 'hooks/randomProducts';
import { Swiper, SwiperSlide } from 'swiper/react';
import AddToFavorite from 'assets/icons/addToFavorite.svg';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const ValentineDays = () => {
  const { randomProducts } = useRandomProducts(4);
  const [activeIndex, setActiveIndex] = useState(1);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const navigate = useNavigate();

  return (
    <div className="ValentineDaysContainer">
      <div className="ValentineDaysImageContainer" style={{ backgroundImage: `url(${ImgBackground})` }}>
        <div className="ValentineDaysProductsCountContainer">
          <p className="ValentineDaysProductsCount">19 products</p>
        </div>
        <div className="ValentineDaysProductsTitle" style={{ backgroundImage: `url(${PerfectVD})` }} />
        <p className="ValentineDaysDiscover">Discover selection</p>
      </div>
      <div className="productsSwiper" style={{ position: 'relative' }}>
        <div className="vertical-swiper-indicator" style={{ position: 'absolute', right: 0, top: 0 }}>
          <ScrollNumberIndicator direction="vertical" activeNumber={activeIndex} count={randomProducts?.length} fontSize={12} color="black" />
        </div>
        <Swiper direction="vertical" slidesPerView={1} spaceBetween={2} mousewheel={true} navigation={true} className="vertical-swiper" onSlideChange={(swiper: any) => setActiveIndex(swiper.activeIndex + 1)}>
          {randomProducts?.map((item, index) => (
            <SwiperSlide key={index} className="vertical-slide">
              <div
                onClick={() => navigate(`/product/${item._id}`)}
                className="vertical-carousel-image"
                style={{
                  backgroundImage: `url(${hoveredItem === index ? item.textureImgUrl : item.imgUrl})`,
                }}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
              />
              <p className="ValentineDaysJustDropped">Just dropped</p>
              <div className="vertical-carousel-content">
                <Link to={`/product/${item._id}`}>
                  <p className="vertical-carousel-brand">{item.brand}</p>
                  <p className="vertical-carousel-title">{item.title}</p>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
