import React from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import './style.scss';

export const AccountInfo: React.FC = () => {
    const [user] = useAtom(userAtom);

    const calculateAge = (birthDateString: string | undefined): string => {
        if (!birthDateString) return 'Not available';

        const birthDate = new Date(birthDateString);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return `${age} yo`;
    };

    return (
        <div className="user-header">
            <div className="header-container">
                <div className="title-button-container">
                    <h3 className="account-info-title">Account Information</h3>
                    <Link to="edit-account" className="edit-button">EDIT</Link>
                </div>
            </div>
            <div className="devider"></div>
            <div className="user-data">
                <div className="user-details">
                    <div className="user-name-email">
                        <p className="details-text">{user?.name || 'Not available'}</p>
                        <p className="details-text">{user?.email || 'Not available'}</p>
                    </div>
                    <div className="user-lastname-phone">
                        <p className="details-text">{user?.lastName || 'Not available'}</p>
                        {/* <p className="details-text">{user?.phoneNumber?.toString() || 'Not available'}</p> */}
                    </div>
                    <p className="details-text">{calculateAge(user?.SPF?.age)}</p>
                </div>
            </div>
        </div>
    );
};
