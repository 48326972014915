import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TConcern } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import { SignupNavbar } from '../../../components/navbars/SignupNavbar/SignupNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import '../style.scss';

export const SPF_Concerns: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const [selectedConcerns, setSelectedConcerns] = useState<TConcern[]>([]);
  const concerns: TConcern[] = [
    'Dryness and/or deshydration',
    'Fine lines and wrinkles',
    'Shiny areas',
    'Redness & rosacea',
    'Eye bags and/or dark circles',
    'Hyperpigmentation',
    'Acne, spots',
    'Blackheads',
    'Dullness',
    'To define'
  ];

  const handleConcernSelect = (concern: TConcern) => {
    setSelectedConcerns((prev) =>
      prev.includes(concern) ? prev.filter((c) => c !== concern) : [...prev, concern]
    );
  };

  useEffect(() => {
    if (user && user.SPF && Array.isArray(user.SPF.concerns)) {
      setSelectedConcerns(user.SPF.concerns);
    } else {
      console.warn('No valid concerns found or user is null.');
    }
  }, [user]);

  const goToNext = () => {
    if (selectedConcerns.length) {
      setSkinProfile({ ...skinProfile, concerns: selectedConcerns });
      navigate('/spf-form/spf-risks');
    }
  };

  return (
    <div className="headerBlockFormsSkin" style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <SignupNavbar />
      <div className="form-skin-profile-container">
        <div className="form-container-skin">
          <StepIndicator currentStep={3} />
          <h1 className="skin-title">WHAT ARE YOUR SKIN CONCERNS?</h1>
          <h5 className="skin-subtitle">Time is beautiful and unique, your age allows us to best meet your needs.</h5>
          <div className="input-group">
            {concerns.map((concern) => (
              <button
                key={concern}
                className={`gender-button ${selectedConcerns.includes(concern) ? 'selected' : ''}`}
                onClick={() => handleConcernSelect(concern)}
              >
                {concern}
              </button>
            ))}
          </div>
          <button className="next-button-form" disabled={!selectedConcerns.length} onClick={goToNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
