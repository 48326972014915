import React from 'react';
import { useNavigate } from 'react-router-dom';
import StartDiagnosiseBg from '../../../../../assets/images/StartDiagnosiseBg.jpeg'
import './style.scss';

export const StartDiagnosis: React.FC = () => {
    const navigate = useNavigate();

    const startDiagnosis = () => {
        navigate('/spf-form');
    };

    return (
        <div className="start-diagnosis-container">
            <img src={StartDiagnosiseBg} alt="Start Diagnosise" className="start-diagnosis-image" />
            <h4 className="start-diagnosis-title">
                Start your skincare<br />journey with oli
            </h4>
            <button className="start-diagnosis-button" onClick={startDiagnosis}>Start my diagnosis</button>
        </div>
    );
};