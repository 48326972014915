import './style.scss';
import Background from 'assets/images/formBackground2.png';
import FontImg from 'assets/textFont/latestTrends.svg';
import latetTrendsFont from 'assets/textFont/Latest trends1.svg';
import latetTrendsFont2 from 'assets/textFont/Latest trends2.svg';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { useNavigate } from 'react-router-dom';

export const LatestTrends = () => {
  const navigate = useNavigate();

  const handleSeeAllArticles = () => {
    navigate('/feed');
  };

  return (
    <div className="LatestTrendsContainer" style={{ backgroundImage: `url(${Background})` }}>
      {window.innerWidth > 768 ? (
        <img src={FontImg} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
      ) : (
        <>
          <img src={latetTrendsFont} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
          <img src={latetTrendsFont2} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
        </>
      )}
      <p className="LatestTrendsContainerHeader">Ensure that you remain up-to-date with the latest trends and discoveries in the skincare industry.</p>
      <DefaultButton marginTop={46} buttonColor="lightYellow" text="SEE ALL ARTICLES" onClick={handleSeeAllArticles} />
    </div>
  );
};
