import React from 'react';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import { Link } from 'react-router-dom'
import './style.scss';

export const Security: React.FC = () => {
    const [user] = useAtom(userAtom);

    return (
        <div className="security-container">
            <div className="header-section">
                <h3 className="security-title">Security</h3>
                <Link to="change-password" className="btn-change">CHANGE</Link>
            </div>
            <div className="divider" />
            <div className="password-section">
                <h5 className="password-label">PASSWORD</h5>
                <span className="password-mask">********************</span>
            </div>
        </div>
    );
};
