import React from 'react';
import './style.scss';
import { Layout } from '../layout/layout';
import { AccountInfo } from './components/AccountInfo/AccountInfo';
import { AddressBook } from './components/AddressBook/AddressBook';
import { Security } from './components/Security/Security';
import { ContactBox } from './components/ContactBox/ContactBox';
import { CautionBox } from './components/CautionBox/CautionBox';

export const ProfileSettings: React.FC = () => {
    return (
        <Layout>
            <div className="account-info-content">
                <CautionBox />
                <AccountInfo />
                <AddressBook />
                <Security />
                <ContactBox />
            </div>
        </Layout>
    );
};
