import React from 'react';
import { useState } from 'react';
import './style.scss';
import { ProductsCarousel } from 'components/carousels/ProductsCarousel';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';

export const RecentlyViewed: React.FC = () => {
  const [activeGroup, setActiveGroup] = useState(1);

  const handleSlideChange = (groupIndex: number) => {
    setActiveGroup(groupIndex);
  };
  return (
    <div className="recommandedContainer" style={{ backgroundColor: '#F7F6E7' }}>
      <div className="BSheaderBlock">
        <p className="header">Recently Viewed</p>
      </div>
      <ProductsCarousel onSlideChange={handleSlideChange} carouselId={10} />

      <div className="indicatorContainer">
        <ScrollNumberIndicator count={3} activeNumber={activeGroup} fontSize={12} color="black" />
      </div>
    </div>
  );
};
