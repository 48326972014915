//const dotenv = require('dotenv');
//dotenv.config();

export const config = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL || 'https://l2ymsa6gyj.execute-api.us-east-1.amazonaws.com',
  REACT_APP_STRIPE_PUBLISHABLE_TEST: process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST,
  REACT_APP_STRIPE_PUBLISHABLE_LIVE: process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE,
  REACT_APP_SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || '',
  ENV: process.env.REACT_APP_ENV || 'local',
  REACT_APP_BRAND_NAME: 'Midnight 00',
};
