import { NavigateFunction } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { IUser } from '../types/TInterfaces';
import { handleLogOut } from '../components/navbars/utils/authUtils';

export const handleTokenExpiration = (
    error: any,
    setUser: Dispatch<SetStateAction<IUser | null>>,
    navigate: NavigateFunction,
    returnPath?: string
) => {
    if (error?.response?.data?.status === 'TOKEN_INVALID' && localStorage.getItem('user')) {
        handleLogOut(setUser, navigate);
        navigate('/login', {
            state: {
                errorMessage: 'Your session has expired. Please login again.',
                returnPath: returnPath || window.location.pathname
            }
        });
        return true;
    }
    return false;
};