import React from 'react';
import inviteFriendsBg from '../../../../../assets/images/InviteFriendsBg.jpeg'
import './style.scss'; // Create this file for styling

export const InviteFriends: React.FC = () => {
    return (
        <div className="invite-friends-container">
            <img src={inviteFriendsBg} alt="Invite Friends" className="invite-image" />
            <h4 className="invite-title">Invite Your Friends</h4>
            <p className="invite-text">Lorem ipsum dolores magna dolores magna dolores magna</p>
            <button className="invite-button">send invitation</button>
        </div>
    );
};