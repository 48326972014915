import axios from 'axios';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',

  },
});

api.interceptors.request.use(
  (config) => {
    // Logic before sending the request, e.g., adding tokens
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // Logic to handle successful responses
    return response;
  },
  (error) => {
    // Logic to handle response errors
    return Promise.reject(error);
  }
);

export default api;
