import '../counter/style.scss';
import React from 'react';

interface StepIndicatorProps {
  currentStep: number;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep }) => {
  return (
    <div className="step-indicator">
      <span className={`step ${currentStep === 1 ? 'active' : ''}`}>{currentStep === 1 ? 'STEP 01' : '01'}</span>
      <span className={`step ${currentStep === 2 ? 'active' : ''}`}>{currentStep === 2 ? 'STEP 02' : '02'}</span>
      <span className={`step ${currentStep === 3 ? 'active' : ''}`}>{currentStep === 3 ? 'STEP 03' : '03'}</span>
      <span className={`step ${currentStep === 4 ? 'active' : ''}`}>{currentStep === 4 ? 'STEP 04' : '04'}</span>
      <span className={`step ${currentStep === 5 ? 'active' : ''}`}>{currentStep === 5 ? 'STEP 05' : '05'}</span>
      <span className={`step ${currentStep === 6 ? 'active' : ''}`}>{currentStep === 6 ? 'STEP 06' : '06'}</span>
    </div>
  );
};
