import React from 'react';
import './style.scss';
import { Layout } from '../layout/layout';
import { SkinData } from './components/SkinData/SkinData';

export const SkinResume: React.FC = () => {
    return (
        <Layout>
            <div className="skin-resume-content">
                <SkinData />
            </div>
        </Layout>
    );
};
