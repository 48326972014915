import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './style.scss';
import { RecommandedProducts } from 'components/homepage/Recommended/RecommandedProducts';
import { RecentlyViewed } from 'components/homepage/RecentlyViewed/RecentlyViewed';
import { SimmilarProducts } from 'components/homepage/SimilarProducts/SimilarProducts';
import productsApi from '../../../src/service/API/ProductsApi';
import { IProduct, IProductInCart } from '../../types/TInterfaces';
import RiskIcon from '../../assets/icons/Vectorbg.svg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import RiskValueBackground from '../../assets/images/RiskValueBackground.png';
import { BestSellersSection } from 'components/homepage/BestSellersSection/BestSellersSection';
import { userAtom } from '../../stores/UserProfile';
import { useAtom } from 'jotai';
import userService from '../../service/UserService';
import { useNavigate } from 'react-router-dom';
import { loadLocalCart, addToLocalCart } from '../../service/localCartService';
import { cartItemsAtom } from '../../stores/UserProfile';
import advicesImg from '../../assets/images/advices.png';
import blackLogo from '../../assets/icons/blackLogo.svg';
import { IProductIngredient } from '../../types/TInterfaces';
import { ProductLoader } from './ProductLoader';
import i from '../../assets/icons/i.svg';
import alertrisk from '../../assets/icons/alertrisk.svg';
//Olis design numbers
import slash from '../../assets/numbers/slash.png';
import num0 from '../../assets/numbers/10.png';
import num1 from '../../assets/numbers/01.png';
import num2 from '../../assets/numbers/02.png';
import num3 from '../../assets/numbers/03.png';
import num4 from '../../assets/numbers/04.png';
import num5 from '../../assets/numbers/05.png';
import num6 from '../../assets/numbers/06.png';
import num7 from '../../assets/numbers/07.png';
import num8 from '../../assets/numbers/08.png';
import num9 from '../../assets/numbers/09.png';
import translations from '../../translations/index';



interface IRisk {
  title: string;
  description: string;
  value: number;
  ingredients: {
    title: string;
    level: string;
  }[];
}

const initialRisks: IRisk[] = [
  {
    title: translations.product.risks.headers.CANCER_RISK,
    description: translations.product.risks.descriptions.NO_RISKED,
    value: 0,
    ingredients: [],
  },
  {
    title: translations.product.risks.headers.IMMUNOTOXICITY,
    description: translations.product.risks.descriptions.NO_RISKED,
    value: 0,
    ingredients: [],
  },
];

export const ProductDetail = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<IProduct | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [productRisks, setProductRisks] = useState<IRisk[]>(initialRisks);
  const [totalRisk, setTotalRisk] = useState<string>('NO RISK');
  const [isRiskListOpen, setIsRiskListOpen] = useState(false);
  const [isCancerListOpen, setIsCancerListOpen] = useState(false);
  const [isImmunotoxicityListOpen, setIsImmunotoxicityListOpen] = useState(false);
  const [isIngredientsOpen, setIsIngredientsOpen] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [inCart, setInCart] = useState<boolean>(false);
  const [selectedSize, setSelectedSize] = useState<string>('');
  const navigate = useNavigate();
  const [localCart, setLocalCart] = useState<IProductInCart[]>([]);
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);
  const [mainImage, setMainImage] = useState('');
  const [inStock, setInStock] = useState<boolean>(false);
  const [brandName, setBrandName] = useState<string>('sss');

  //dynamicly create score image according to the score number 
  const getNumberImage = (digit: string) => {
    const numberImages: { [key: string]: string } = {
      '0': num0, '1': num1, '2': num2, '3': num3, '4': num4,
      '5': num5, '6': num6, '7': num7, '8': num8, '9': num9
    };
    return numberImages[digit];
  };

  const handleMiniImageClick = (imgUrl: string) => {
    setMainImage(imgUrl);
  };
  useEffect(() => {
    if (productId) {
      fetchProduct(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (product?.brand) {
      setBrandName(product?.brand);
    }
  }, [product?.brand]);

  useEffect(() => {
    const storedCart = loadLocalCart();
    if (storedCart) setLocalCart(storedCart);
    else console.error('Error loading local cart');
  }, [cartItems]);

  useEffect(() => {
    if (user?.cart && product?._id) {
      setInCart(user.cart.some(item => item.product?._id === product._id));
    } else if (localCart && product?._id) {
      setInCart(localCart.some(item => item.product._id === product._id));
    } else {
      setInCart(false);
    }
  }, [user?.cart, localCart, product?._id]);

  useEffect(() => {
    const checkAvailability = async () => {
      try {
        if (product?.sku) {
          const result = await productsApi.checkProductAvailability(product.sku);

          setInStock(result.available);
        } else {
          setInStock(false);
        }
      } catch (error) {
        console.error("Error checking product availability:", error);
        setInStock(false);
      }
    };

    checkAvailability();

  }, [product?.sku]);


  const fetchProduct = async (id: string) => {
    try {
      setLoading(true);
      const result = await productsApi.getProductById(id);

      if (result.success && result.data) {
        setProduct(result.data as IProduct);
        setMainImage(result?.data?.imgUrl || '');
        const mappedIngredients = result.data.ingredients?.map(ing => ({
          ingredient_id: typeof ing === 'string' ? ing : ing._id.toString(),
          title: typeof ing === 'string' ? '' : (ing as any).titleEwg,
          concerns: typeof ing === 'string' ? [] : ((ing as any).commonConcerns || []),
          functions: typeof ing === 'string' ? [] : ((ing as any).functions || []),
          score: typeof ing === 'string' ? 0 : ((ing as any).score || 0),
          scoreMax: typeof ing === 'string' ? 100 : ((ing as any).scoreMax || 100),
          scoreMin: typeof ing === 'string' ? 0 : ((ing as any).scoreMin || 0)
        }));
        if (mappedIngredients) {
          calculateRisks(mappedIngredients);
        }
      } else {
        setProduct(null);
        setError(result.message || 'Failed to fetch product');
      }
    } catch (err) {
      setProduct(null);
      setError('An error occurred while fetching the product');
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (productId) {
  //       fetchProduct(productId);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, [productId]);





  const saveToBasket = async () => {
    try {
      if (user && product) {
        const result = await userService.addtocart(user, setUser, product, navigate);

        if (result?.success) {
          setInCart(true);
        } else if (result.message === 'Session expired. Please login again.') {
          navigateToLogin('Please login to add items to your cart');
        } else {
          console.error("Error adding product to cart", result.message);
          alert("Error adding product to cart")
          // setError(result.message);
        }
      } else if (product) {
        const updatedCart = addToLocalCart({ product, quantity: 1 });
        setLocalCart(updatedCart);
        setCartItems(updatedCart);
        setInCart(true);
      }
    } catch (e) {
      console.error('Saving product to basket on ProductPage.tsx failed', e);
    }
  };

  const navigateToLogin = (errorMessage?: string) => {
    navigate('/login', {
      state: {
        errorMessage,
        returnPath: `/product/${productId}`
      }
    });
  };


  const calculateRisks = (ingredients: IProductIngredient[]) => {
    const newRisks = initialRisks.map((risk) => {
      const filteredConcerns = ingredients
        .map((productConcern) => ({
          ...productConcern,
          concerns: productConcern.concerns.filter((concern) => (risk.title === 'Cancer RISK' && concern.concern === 'Cancer') || (risk.title === 'Immunotoxicity' && concern.concern === 'Allergies & Immunotoxicity')),
        }))
        .filter((product) => product.concerns.length > 0)
        .flatMap((product) =>
          product.concerns.map((concern) => ({
            title: product.title,
            level: concern.level,
          }))
        );

      const higherRiskCount = filteredConcerns.reduce((count, concern) => {
        return concern.level !== 'LOW' ? count + 1 : count;
      }, 0);

      const riskPoints = filteredConcerns.reduce((total, concern) => {
        switch (concern.level) {
          case 'MODERATE':
            return total + 1;
          case 'HIGH':
            return total + 2;
          default:
            return total;
        }
      }, 0);

      return {
        ...risk,
        ingredients: filteredConcerns,
        value: riskPoints,
        description: `${higherRiskCount} risked ingredients`,
      };
    });
    setProductRisks(newRisks);
    determineTotalRisk(newRisks);
  };
  // Determine the total risk based on the risks array
  const determineTotalRisk = (risks: IRisk[]) => {
    const cancerRisk = risks.find((risk) => risk.title === 'Cancer RISK')?.value || 0;
    const immunotoxicityRisk = risks.find((risk) => risk.title === 'Immunotoxicity')?.value || 0;

    if (cancerRisk > 0) {
      setTotalRisk('HIGH');
    } else if (immunotoxicityRisk > 0) {
      setTotalRisk('MODERATE');
    } else {
      setTotalRisk('NO RISK');
    }
  };

  const toggleRiskList = () => {
    if (totalRisk === 'MODERATE' || totalRisk === 'HIGH') {
      setIsRiskListOpen(!isRiskListOpen);
    }
  };

  const toggleCancerList = () => {
    setIsCancerListOpen(!isCancerListOpen);
  };

  const toggleImmunotoxicityList = () => {
    setIsImmunotoxicityListOpen(!isImmunotoxicityListOpen);
  };

  const toggleIngredientsList = () => {
    setIsIngredientsOpen(!isIngredientsOpen);
  };

  const navigateToBrand = (brandName: string) => {
    navigate(`/brand/${brandName.toLowerCase()}`);
  };

  const isHighOrModerateRisk = (ingredientTitle: string) => {
    const risk = productRisks.find(risk => risk.ingredients.some(ing => ing.title === ingredientTitle && (ing.level === 'MODERATE' || ing.level === 'HIGH')));
    return risk !== undefined;
  };

  if (error) {
    // TODO - Error page
    //navigate('/shop');
    setError(null);
    fetchProduct(productId || '');
    return <div className="brand-loader">
      <ProductLoader />
    </div>
    //return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div className="brand-loader">
      <ProductLoader />
    </div>
  }

  return (
    <div className="productDetailsContainer">
      <HomepageNavbar />
      <div className="product-detail-container">
        <div className="side-menu" style={{ visibility: loading ? 'hidden' : 'visible' }}>

          <div className="advice-container">
            <div className="advice-container">
              <img src={blackLogo} alt="Logo" className="advice-logo" />
              <img src={advicesImg} alt="Advices" className="advice-image" />
            </div>
            <div>
              <div className="score-display">
                {(product?.score || '0').toString().padStart(2, '0').split('').map((digit, index) => (
                  <img
                    key={index}
                    src={getNumberImage(digit)}
                    alt={digit}
                    className="score-digit"
                  />
                ))}
                <img src={slash} alt="/" className="score-digit" />
                {['1', '0', '0'].map((digit, index) => (
                  <img
                    key={`total-${index}`}
                    src={getNumberImage(digit)}
                    alt={digit}
                    className="score-digit"
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className="risk-status-bg"
            style={{
              backgroundColor: totalRisk === 'MODERATE' || totalRisk === 'HIGH' ? '#5D5246' : '#D6CBBF',
              color: totalRisk === 'MODERATE' || totalRisk === 'HIGH' ? '#F7F6E6' : '#000000',
              cursor: totalRisk !== 'NO RISK' ? 'pointer' : 'default',
            }}
            onClick={toggleRiskList}
          >
            <p
              style={{
                color: totalRisk === 'MODERATE' || totalRisk === 'HIGH' ? '#F7F6E6' : '#000000',
              }}
            >
              POTENTIAL RISK

            </p>
            <p
              style={{
                color: totalRisk === 'MODERATE' || totalRisk === 'HIGH' ? '#F7F6E6' : '#000000',
              }}
            >
              <img src={RiskIcon} alt="Risk Icon" className="risk-icon" />
              {totalRisk}
            </p>
          </div>

          {isRiskListOpen && (
            <div className="risk-list">
              {(() => {
                const cancerRisk = productRisks.find((risk) => risk.title === 'Cancer RISK');
                return cancerRisk && cancerRisk.value > 0 ? (
                  <div className={`risk-item ${isCancerListOpen ? 'active' : ''}`}>
                    <h3 onClick={toggleCancerList} className="riskStatus">
                      <div className="risk-header">
                        <div className="risk-image-container">
                          <img src={RiskValueBackground} alt="Risk Background" className="risk-background" />
                          <span className="risk-value-on-image">{cancerRisk.value}</span>
                        </div>
                        <span className="risk-title">CANCER</span>
                      </div>
                      <div className="plus">
                        <span className="risk-info">{cancerRisk.ingredients.filter((ingredient) => ingredient.level === 'MODERATE' || ingredient.level === 'HIGH').length} risked ingredients</span>
                        <span className="toggle-icon">{isCancerListOpen ? '−' : '+'}</span>
                      </div>
                    </h3>
                    {isCancerListOpen && (
                      <ul className="ingredient-list">
                        {cancerRisk.ingredients
                          .filter((ingredient) => ingredient.level !== 'LOW')
                          .map((ingredient, index) => (
                            <li className="ingr-risk" key={index}>
                              <span>{ingredient.title}</span> <span>{ingredient.level}</span>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                ) : null;
              })()}

              {(() => {
                const immunotoxicityRisk = productRisks.find((risk) => risk.title === 'Immunotoxicity');
                return immunotoxicityRisk && immunotoxicityRisk.value > 0 ? (
                  <div className={`risk-item ${isImmunotoxicityListOpen ? "active" : ""}`}>
                    <h3 onClick={toggleImmunotoxicityList} className="riskStatus">
                      <div className="risk-header">
                        <div className="risk-image-container">
                          <img src={RiskValueBackground} alt="Risk Background" className="risk-background" />
                          <span className="risk-value-on-image">{immunotoxicityRisk.value}</span>
                        </div>
                        <span className="risk-title">IMMUNOTOXICITY</span>
                      </div>
                      <div className="plus">
                        <span className="risk-info">
                          {immunotoxicityRisk.ingredients.filter((ingredient) => ingredient.level === 'MODERATE' || ingredient.level === 'HIGH').length} risked ingredients
                        </span>
                        <span className="toggle-icon">{isImmunotoxicityListOpen ? '−' : '+'}</span>
                      </div>
                    </h3>
                    {isImmunotoxicityListOpen && (
                      <ul className="ingredient-list">
                        {immunotoxicityRisk.ingredients
                          .filter((ingredient) => ingredient.level !== 'LOW')
                          .map((ingredient, index) => {
                            const matchingIngredient = product?.ingredients?.find(prodIng => prodIng.titleEwg === ingredient.title);
                            const isHighOrModerate = ingredient.level === 'HIGH' || ingredient.level === 'MODERATE';

                            return (
                              <li className="ingr-risk" key={index}>
                                <span>{ingredient.title}</span>
                                <div className="icon-container">
                                  {matchingIngredient?.urlEwg ? (
                                    <a href={matchingIngredient.urlEwg} target="_blank" rel="noopener noreferrer" className="ewg-link">
                                      {ingredient.level}
                                    </a>
                                  ) : (
                                    <span className="risk-level">
                                      {ingredient.level}
                                    </span>
                                  )}

                                  {isHighOrModerate && (
                                    <>
                                      <img src={alertrisk} alt="alert risk" className="alert-icon" />
                                      <img src={i} alt="info icon" className="info-icon" />
                                    </>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </div>
                ) : null;

              })()}
            </div>
          )}

          <div className={`ingredients-list ${isIngredientsOpen ? 'active' : ''}`}>
            <div className="toggle-header-Ingredients">
              <h3 onClick={toggleIngredientsList}>Ingredients List</h3>
              <button onClick={toggleIngredientsList} className="toggle-icon-IngredientsOpen">
                {isIngredientsOpen ? '−' : '+'}
              </button>
            </div>
            {isIngredientsOpen && (
              <>
                {product?.ingredients && product.ingredients.length > 0 && (
                  <ul className='ingredients-ul'>
                    {product.ingredients.map((ingredient, index) => (
                      <li key={`found-${index}`}>
                        <div className="ingredient-item">
                          {ingredient?.titleEwg && isHighOrModerateRisk(ingredient.titleEwg) && (
                            <img src={RiskIcon} alt="Risk Icon" className="risk-icon" />
                          )}
                          <span className="ingredient-title">{ingredient?.titleEwg}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>

          {/* <div className="skin-recommendation">
            <h3>Skin Recommendation</h3>
            <p>
              <img src={RiskIcon} alt="Risk Icon" className="risk-icon" />
              Perfect Match
            </p>
          </div> */}
        </div>

        <div className="product-image-section">
          <div className="product-image">
            {loading ? (
              <ProductLoader />
            ) : (
              <img src={mainImage} alt={product?.title} />
            )}
          </div>

          <div className="mini-image" style={{ visibility: loading ? 'hidden' : 'visible' }}>
            <img
              src={product?.imgUrl || ''}
              alt="Mini version of product"
              onClick={() => handleMiniImageClick(product?.imgUrl || '')}
              style={{ cursor: 'pointer' }}
            />
            {product?.textureImgUrl && (
              <img
                src={product.textureImgUrl}
                alt="Mini texture of product"
                onClick={() => handleMiniImageClick(product?.textureImgUrl || '')}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </div>

        <div className="product-info" style={{ visibility: loading ? 'hidden' : 'visible' }}>
          <div className="brand-name">
            <h1 onClick={() => navigateToBrand(product?.brand || 'Untitled-Brand')}>
              {product?.brand || 'Untitled Product'}
            </h1>
            <p>{product?.price ? `€${product?.price}` : 'Price not available'}</p>
          </div>

          <p className="product-brand">{product?.title || 'Product title not available'}</p>

          <p className="product-desc">{product?.description || 'Product description not available'}</p>
          <div className="certificates">
            {product?.certificates &&
              product?.certificates
                .filter((cert) => cert !== 'Unknown')
                .map((cert, index) => (
                  <span key={index} className="certificate-badge">
                    {cert}
                  </span>
                ))}
          </div>

          <div className="product-sizes">
            <h3>CHOOSE A SIZE</h3>
            <div className="size-options">
              <button
                className={`size-button ${selectedSize === '250ml' ? 'active' : ''}`}
                onClick={() => setSelectedSize('250ml')}
              >
                {product?.quantity || '250'} ml <span>{product?.price ? `€${product?.price}` : 'Price not available'}</span>
              </button>

              {/* 
              TODO: when we will have different sizes for product we support different sizes and prices
              <button
                className={`size-button ${selectedSize === '500ml' ? 'active' : ''}`}
                onClick={() => setSelectedSize('500ml')}
              >
                500ml <span>${product.price}</span>
              </button>
              <button
                className={`size-button ${selectedSize === '750ml' ? 'active' : ''}`}
                disabled
              >
                750ml <span>${product.price}</span>
              </button> */}
            </div>
          </div>
          <button
            className="add-to-cart"
            disabled={inCart || !inStock}
            onClick={() => saveToBasket()}
          >
            {inStock ? (inCart ? 'In basket' : `Add to Basket - from €${product?.price ?? 24.99}`) : 'Out of Stock'}
          </button>
        </div>

      </div>
      <RecommandedProducts brandName={brandName || 'Untitled-Brand'} />
      <SimmilarProducts />
      <RecentlyViewed />
    </div>
  );
};
