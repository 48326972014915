import './style.scss';
import axios from 'axios';
import React, { useState } from 'react';
import { SignupNavbar } from '../../components/navbars/SignupNavbar/SignupNavbar';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import globalService from '../../service/GlobalService';
import { config } from 'config';
import { validateEmail } from '../../utils/inputValidationUtil';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';

export const SignUpPage = () => {
  const [, setUser] = useAtom(userAtom);
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleNextStep = () => {
    // Step 1: Validate email format
    if (currentStep === 1) {
      if (!email || !validateEmail(email)) {
        setError('Please enter a valid email address.');
        return; // Prevent moving to the next step
      }
    }

    // Step 2: Validate password match
    if (currentStep === 2) {
      if (!password || password.trim() === '') {
        setError('Password is required.');
        return; // Prevent moving to the next step
      }

      if (password !== confirmPassword) {
        setError('Passwords do not match.');
        return; // Prevent moving to the next step
      }
    }

    // Move to the next step if validations pass
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (error) setError(null);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (error) setError(null);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (error) setError(null);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (error) setError(null);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    if (error) setError(null);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate inputs before submitting
    if (!email || !validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!password || password.trim() === '') {
      setError('Password is required.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (!name || name.trim() === '') {
      setError('Name is required.');
      return;
    }

    if (!lastName || lastName.trim() === '') {
      setError('Last name is required.');
      return;
    }

    try {
      const response = await axios.post(`${config.REACT_APP_API_URL}/auth/register`, {
        data: {
          email,
          password,
          name,
          lastName,
        },
      });

      const { user, accessToken, refreshToken } = response.data;

      await globalService.saveUserAndTokens(accessToken, refreshToken, user, setUser);
      window.location.href = '/spf-form';
    } catch (err: any) {
      const errorMessage = err.response?.data?.error || 'Registration error occurred';
      console.error('Registration error:', errorMessage);
      setError(errorMessage);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      if (currentStep < 3) {
        handleNextStep();
      } else {
        handleSubmit(e as unknown as React.FormEvent); // Type casting to match handleSubmit parameter
      }
    }
  };

  const renderStepContent = () => {
    const inputErrorClass = error ? 'error' : '';

    switch (currentStep) {
      case 1:
        return (
          <div className="input-group">
            <input
              type="email"
              placeholder="EMAIL ADDRESS"
              value={email}
              onChange={handleEmailChange}
              className={`input ${inputErrorClass}`}
              required
              onKeyDown={handleKeyDown} // Attach key down event
            />
            {error && <p className="error-message">{error}</p>}
          </div>
        );
      case 2:
        return (
          <>
            <div className="input-group">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="PASSWORD"
                value={password}
                onChange={handlePasswordChange}
                className={`input ${inputErrorClass}`}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
              <button
                type="button"
                className="show-password-btn"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? 'HIDE' : 'SEE'}
              </button>
            </div>
            <div className="input-group">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="CONFIRM PASSWORD"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className={`input ${inputErrorClass}`}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
              <button
                type="button"
                className="show-password-btn"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? 'HIDE' : 'SEE'}
              </button>
            </div>
            {error && <p className="error-message">{error}</p>}
          </>
        );
      case 3:
        return (
          <>
            <div className="input-group">
              <input
                type="text"
                placeholder="NAME"
                value={name}
                onChange={handleNameChange}
                className={`input ${inputErrorClass}`}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="LAST NAME"
                value={lastName}
                onChange={handleLastNameChange}
                className={`input ${inputErrorClass}`}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
            </div>
            {error && <p className="error-message">{error}</p>}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="headerBlock" style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
        <HomepageNavbar />
        <div className="sign-up-container">
          <div className="form-container">
            <img className="group" alt="Group" src={blackLogo} />
            <h1 className="signup-title">CREATE ACCOUNT</h1>
            <p className="step-indicator">
              <span className={`step ${currentStep === 1 ? 'active' : ''}`}>
                {currentStep === 1 ? 'STEP ' : ''}01
              </span>
              <span className={`step ${currentStep === 2 ? 'active' : ''}`}>
                {currentStep === 2 ? 'STEP ' : ''}02
              </span>
              <span className={`step ${currentStep === 3 ? 'active' : ''}`}>
                {currentStep === 3 ? 'STEP ' : ''}03
              </span>
            </p>
            <form onSubmit={handleSubmit}>
              {renderStepContent()}
              <button
                type={currentStep < 3 ? 'button' : 'submit'}
                className="next-button"
                onClick={currentStep < 3 ? handleNextStep : undefined}
                disabled={currentStep === 1 && !email}
              >
                {currentStep < 3 ? 'NEXT' : 'SUBMIT'}
              </button>
            </form>

            <p className="login-text">
              You've already an account?{' '}
              <a href="/login" className="login-link">
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};


