import React, { useState } from 'react';
import { Layout } from '../../../layout/layout';
import userService from '../../../../../service/UserService';
import { userAtom } from '../../../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from 'utils/inputValidationUtil';
import './style.scss';

export const AccountForm: React.FC = () => {
    const [user, setUser] = useAtom(userAtom);
    const [formData, setFormData] = useState({
        name: user?.name || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
    });
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === 'email') {
            setEmailError(validateEmail(value) ? null : 'Invalid email format.');
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formData.name || !formData.lastName || !formData.email) {
            setError('Please fill all fields.');
            return;
        }

        if (emailError) {
            setError('Please fix the errors before submitting.');
            return;
        }

        if (!user) {
            setError('User not found.');
            return;
        }

        const result = await userService.updateUser(user, setUser, formData, navigate);

        if (result.success) {
            navigate(-1);
        } else {
            setError(result.message);
        }
    };


    return (
        <Layout>
            <div className="form-container">
                <button className="account-back-button" onClick={handleBack}>{'< BACK'}</button>

                <div className="form-header">
                    <h5>EDIT ACCOUNT INFORMATION</h5>
                </div>
                {error && <p className="error-message">{error}</p>}
                <form className="account-form" onSubmit={handleSubmit}>
                    <div className="form-field">
                        <input
                            type="text"
                            name="name"
                            placeholder="FIRST NAME"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-field">
                        <input
                            type="text"
                            name="lastName"
                            placeholder="LAST NAME"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-field">
                        <input
                            type="email"
                            name="email"
                            placeholder="EMAIL"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {emailError && <p className="account-error-message">{emailError}</p>}
                    </div>
                    <button type="submit" className="submit-button">SAVE CHANGES</button>
                </form>
            </div>
        </Layout>
    );
};