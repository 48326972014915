import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import userService from 'service/UserService';
import { SignupNavbar } from '../../../components/navbars/SignupNavbar/SignupNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import blackLogo from '../../../assets/icons/blackLogo.svg';
import { StepIndicator } from 'components/counter/counter'; // Import the StepIndicator
import '../style.scss';

export const SPF_Resume: React.FC = () => {
  const [skinProfile] = useAtom(skinProfileAtom);
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();

  const saveProfile = async () => {
    if (!user || !user._id) {
      console.error('User is not logged in');
      return;
    }

    try {
      user && skinProfile && (await userService.updateUserSPF(user, setUser, skinProfile));
      navigate('/spf-form/spf-analyze');
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const handleEdit = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    console.log('Current skinProfileAtom state:', skinProfile);
    console.log('user', user);
  }, [skinProfile, user]);

  return (
    <div className="headerBlockFormsSkin" style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <SignupNavbar />
      <div className="form-skin-profile-container">
        <div className="form-container-skin">
          <StepIndicator currentStep={5} /> {/* Step 5 */}
          <h1 className="resume-title">MY SKIN RESUME</h1>
          <h5 className="resume-subtitle">Please review your details before saving your profile.</h5>
          <div className="skin-review-section">
            <div className="skin-review-details">
              <p className="skin-review-number">01</p>
              <div>
                <p className="skin-review-label">SKIN TYPE</p>
                <p className="skin-review-value">{skinProfile?.skinType || 'Not specified'}</p>
              </div>
            </div>
            <button className="skin-review-edit-button" onClick={() => handleEdit('/spf-form/spf-skin-type')}>
              EDIT
            </button>
          </div>
          {/* Concerns */}
          <div className="skin-review-section">
            <div className="skin-review-details">
              <p className="skin-review-number">02</p>
              <div>
                <p className="skin-review-label">CONCERNS</p>
                <p className="skin-review-value">{skinProfile?.concerns?.length ? skinProfile.concerns.join(', ') : 'Not specified'}</p>
              </div>
            </div>
            <button className="skin-review-edit-button" onClick={() => handleEdit('/spf-form/spf-concerns')}>
              EDIT
            </button>
          </div>
          {/* Risks */}
          <div className="skin-review-section">
            <div className="skin-review-details">
              <p className="skin-review-number">03</p>
              <div>
                <p className="skin-review-label">RISKS</p>
                <p className="skin-review-value">{skinProfile?.risks?.length ? skinProfile.risks.join(', ') : 'Not specified'}</p>
              </div>
            </div>
            <button className="skin-review-edit-button" onClick={() => handleEdit('/spf-form/spf-risks')}>
              EDIT
            </button>
          </div>
          <button className="next-button-form" style={{ marginTop: '60px' }} onClick={saveProfile}>
            CONFIRM MY DIAGNOSIS
          </button>
        </div>
      </div>
    </div>
  );
};
