import { useState, useEffect, useCallback } from 'react';
import styles from './checkout.module.scss';
import { IProduct } from 'types/TInterfaces';
import { userAtom } from '../../stores/UserProfile';
import { useAtom } from 'jotai';
import { IProductInCart, ShippingAddress } from '../../types/TInterfaces';
import PaymentsApi from '../../service/API/checkoutApi';
import valentineDaysBack from '../../assets/images/valentineDaysBack.jpg';
import profileSelectionIcon from 'assets/images/profileSelectionIcon.png';
import { DeliveryService } from '../../types/TInterfaces';
import { AddressCard } from './adressCard';
import userService from '../../service/UserService';
import { loadLocalCart, removeFromLocalCart, updateLocalCartItemQuantity } from '../../service/localCartService';
import { IAnonymousUser, IUser } from '../../types/TInterfaces';
import deliveryApi from '../../service/API/deliveryApi';
import { useNavigate } from 'react-router-dom';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useElements, CardElement, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import globalService from 'service/GlobalService';

export const CheckoutPage = () => {
  const [user, setUser] = useAtom(userAtom);
  const [cartItems, setCartItems] = useState<IProductInCart[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedPayment, setSelectedPayment] = useState('creditCard');
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [tempQuantity, setTempQuantity] = useState<number | null>(null);
  const [deliveryCountries, setDeliveryCountries] = useState<Pick<DeliveryService, 'country'>[]>([]);
  const [allShippingDeliveryServices, setAllShippingDeliveryServices] = useState<DeliveryService[]>([]);
  const [filteredShippingServices, setFilteredShippingServices] = useState<DeliveryService[]>([]);
  const [selectedShippingService, setSelectedShippingService] = useState<DeliveryService | null>(null);
  const [selectedCountry, setSelectedCountry] = useState(user?.email ?? '');
  const [userEmail, setUserEmail] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [shippingAddress, setShippingAddress] = useState<ShippingAddress[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [newAddress, setNewAddress] = useState<ShippingAddress & { _id?: string }>({
    addressName: '',
    first_name: '',
    last_name: '',
    phone: '',
    street: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
    asDefault: false,
  });
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<ShippingAddress>(shippingAddress[selectedAddressIndex]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAnonymousUser, setIsAnonymousUser] = useState(!user || Object.keys(user).length === 0)
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setIsAnonymousUser(!user || Object.keys(user).length === 0)
  }, [user])

  useEffect(() => {
    setSelectedAddress(shippingAddress[selectedAddressIndex]);
  }, [selectedAddressIndex, shippingAddress]);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!user) {
      console.error('User is not logged in');
      return;
    }

    try {
      const accessToken = localStorage.getItem('accessToken');
      const userWithToken = { ...user, token: accessToken };

      if (isEditing) {
        const addressId = selectedAddress?._id;
        if (!addressId) {
          console.error('No address ID found for editing');
          alert('Failed to edit the address. Please try again.');
          return;
        }

        const result = await userService.updateDeliveryAddress(userWithToken, setUser, newAddress, addressId, navigate);

        setIsEditing(false);
        if (result?.success) {
          const userDataResult = await userService.getUserData(userWithToken, setUser);
          if (!userDataResult.success) {
            console.error('Failed to fetch updated user data');
          }
          setIsEditing(false);
        } else {
          throw new Error(result?.message || 'Failed to update address');
        }
      } else {
        const result = await userService.addDeliveryAddress(userWithToken, setUser, newAddress, navigate);
        if (result.success && result.address) {
          setShippingAddress([...shippingAddress, result.address]);

        } else {
          throw new Error(result.message || 'Failed to add new address');
        }
      }

      setNewAddress({
        addressName: '',
        _id: '',
        first_name: '',
        last_name: '',
        phone: '',
        street: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        country: '',
        asDefault: false,
      });
      setIsAdding(false);

    } catch (error) {
      console.error('Error saving address:', error);
      alert('Failed to save the address. Please try again.');
    }
  };

  const handleEdit = (index: number) => {
    setIsEditing(true);
    setSelectedAddressIndex(index);
    const addressToEdit = shippingAddress[index];
    setNewAddress({
      addressName: '',
      _id: addressToEdit._id,
      first_name: addressToEdit.first_name || '',
      last_name: addressToEdit.last_name || '',
      phone: addressToEdit.phone || '',
      street: addressToEdit.street,
      streetNumber: addressToEdit.streetNumber,
      postalCode: addressToEdit.postalCode,
      city: addressToEdit.city,
      country: addressToEdit.country,
    });

  };

  const handleAddNewAddress = () => {
    setNewAddress({
      addressName: '',
      first_name: '',
      last_name: '',
      phone: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      country: '',
      asDefault: false,
    });
    setIsAdding(true);
    setIsEditing(false);
  };

  const handleSelectAddress = (index: number) => {
    setSelectedAddressIndex(index);

    setSelectedCountry(shippingAddress[index].country);
  };

  useEffect(() => {
    setPaymentSuccess(false);
  }, []);

  useEffect(() => {
    if (user) {
      const userCarts = user.cart;
      const userProducts = userCarts.map((item) => item.product);
      if (user.addresses.length > 0) {
        const formattedAddresses = user.addresses.map((address) => ({
          _id: address._id,
          addressName: '',
          first_name: address.firstName || '',
          last_name: address.lastName || '',
          phone: address.phoneNumber?.number || '',
          street: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.postalCode || '',
          city: address.city || '',
          country: address.country || '',
        }));
        setShippingAddress(formattedAddresses);
        setUserEmail(user?.email ?? '');
        setSelectedCountry(formattedAddresses[0].country);
      }
      setProducts(userProducts);
      setCartItems(userCarts);
    } else {

      const localCart = loadLocalCart();
      const userProducts = localCart.map((item) => item.product);
      setProducts(userProducts);
      setCartItems(localCart);
    }
  }, [user]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const { uniqueCountries, allServices } = await deliveryApi.fetchDeliveryServices();
      setDeliveryCountries(uniqueCountries);
      setAllShippingDeliveryServices(allServices);
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const filteredServices = allShippingDeliveryServices.filter(
        (service) =>
          service.country === selectedCountry &&
          service.service !== "Relay/Lockers"  // Filter out Relay/Lockers
      );
      setFilteredShippingServices(filteredServices);

      if (filteredServices.length > 0) {
        setSelectedShippingService(filteredServices[0]);
      }
    } else {
      setFilteredShippingServices([]);
    }
  }, [selectedCountry, allShippingDeliveryServices]);

  const handleCountryChangeForNewAddress = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const country = event.target.value;
    setNewAddress({ ...newAddress, country });
  };

  const getTotalItems = () => cartItems.reduce((total, item) => total + item.quantity, 0);



  const selectedShipmentWithoutTax = (selectedShippingService: DeliveryService | null): number => {
    const taxRate = selectedShippingService?.tax ?? 0;
    const shippingPrice = selectedShippingService?.price ?? 0;

    const taxPrice = shippingPrice / (1 + taxRate / 100);
    return taxPrice

  }

  const calculateTotalAmountIncludingAllTax = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    const { subtotal, productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
    const shippingPrice = (selectedShippingService?.price) ?? 0;
    const totalAmount = subtotal + productTax + shippingPrice;
    console.log("calculateTotalAmountIncludingAllTax ", totalAmount);

    return totalAmount.toFixed(2);

  };

  const calculateTotalAmountIncludingTax = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    const { subtotal, productTax } = calculateCartSubtotal(cartItems, selectedShippingService);

    const totalAmount = subtotal + productTax;
    return totalAmount.toFixed(2);
  };


  const calculateCartSubtotal = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): { subtotal: number, productTax: number } => {
    const taxRate = selectedShippingService?.tax ?? 0;
    let subtotal = 0;
    let productTax = 0;
    cartItems.forEach((item: IProductInCart) => {
      if (item.product.price !== undefined) {
        const priceBeforeVAT = item.product.price / (1 + taxRate / 100);
        const tax = item.product.price - priceBeforeVAT;
        subtotal += priceBeforeVAT * item.quantity;
        productTax += tax * item.quantity;
      }
    });

    subtotal = Math.round(subtotal * 100) / 100;
    productTax = Math.round(productTax * 100) / 100;

    subtotal = subtotal > 0.00 ? parseFloat(subtotal.toFixed(2)) : 0.00;
    productTax = productTax > 0.00 ? parseFloat(productTax.toFixed(2)) : 0.00;
    return { subtotal, productTax };
  };

  const calculateShippingCost = (selectedShippingService: DeliveryService | null): { shippingPrice: string, shippingTax: string, totalShippingCost: string } => {
    if (!selectedShippingService || !selectedShippingService.price || !selectedShippingService.tax) {
      return { shippingPrice: '0.00', shippingTax: '0.00', totalShippingCost: '0.00' };
    }

    const taxRate = selectedShippingService.tax / 100;
    const totalShippingCost = selectedShippingService.price;

    const shippingPrice = totalShippingCost / (1 + taxRate);

    const shippingTax = totalShippingCost - shippingPrice;

    return {
      shippingPrice: shippingPrice.toFixed(2),
      shippingTax: shippingTax.toFixed(2),
      totalShippingCost: totalShippingCost.toFixed(2),
    };
  };

  const handleSelectPayment = (option: string) => {
    setSelectedPayment(option);
  };

  const handleCheckout = async () => {
    if (!validateStripe()) {
      alert('Payment system is not ready. Please try again.');
      return;
    }

    const countryCode = selectedAddress.country.split(' ')[0];
    let selectedUser: IUser | IAnonymousUser;

    const totalWithTax = parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService));
    const isFreeShipping = selectedShippingService && totalWithTax >= selectedShippingService.freeshipping;

    const shippingCost = selectedShippingService && !isFreeShipping
      ? calculateShippingCost(selectedShippingService)
      : { shippingPrice: '0.00', shippingTax: '0.00', totalShippingCost: '0.00' };

    let checkoutData = {
      products: cartItems.map((item) => {
        const taxRate = selectedShippingService?.tax ?? 0;
        const unitPrice = item.product.price ? item.product.price / (1 + taxRate / 100) : 0;
        const unitTax = unitPrice * (taxRate / 100);
        return {
          sku: item.product.sku,
          quantity: item.quantity,
          unitPrice: unitPrice.toFixed(2),
          unitTax: unitTax.toFixed(2),
          discount: '0.00', // TODO: get discount from server when we implement voucher system
        };
      }),
      amount: totalWithTax.toFixed(2),
      shipping: selectedShippingService ? {
        shippingPrice: shippingCost.shippingPrice,
        shippingTax: shippingCost.shippingTax,
        totalShippingCost: shippingCost.totalShippingCost,
        shippingMethod: selectedShippingService.service,
      } : null,
      delivery: {
        firstName: selectedAddress.first_name,
        lastName: selectedAddress.last_name,
        phoneNumber: selectedAddress.phone,
        street: selectedAddress.street,
        streetNumber: selectedAddress.streetNumber,
        city: selectedAddress.city,
        postalCode: selectedAddress.postalCode,
        country: countryCode,
        state: '',
      },
      userId: user ? user._id : '',
      userEmail: userEmail,
    };

    if (user && !isAnonymousUser) {
      checkoutData.userId = user._id ?? '';
      selectedUser = user;
    } else {
      const anonymousUser: IAnonymousUser = {
        email: userEmail,
        created_at: new Date(),
        cart: cartItems,
        addresses: {
          firstName: selectedAddress.first_name,
          lastName: selectedAddress.last_name,
          phoneNumber: selectedAddress.phone,
          street: selectedAddress.street,
          streetNumber: selectedAddress.streetNumber,
          city: selectedAddress.city,
          postalCode: selectedAddress.postalCode,
          country: selectedAddress.country,
        },
        anonymous: true,
      };
      checkoutData.userEmail = anonymousUser.email;
      selectedUser = anonymousUser;
    }

    try {
      // Create Payment Intent
      const result = await PaymentsApi.checkout(selectedUser, setUser, checkoutData);

      if (result.success && result.clientSecret) {
        // Stripe Payment
        await confirmCardPayment(result.clientSecret);

      } else {
        console.error('Checkout failed:', result.message);
        alert('Checkout Failed');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  const removeItemFromCart = async (id: string) => {
    if (user) {
      const product = cartItems.find(item => item.product._id === id);
      if (!product) {
        console.error("Product not found in cart.");
        return;
      }
      const result = await userService.removeFromCart(user, setUser, product, navigate);

      if (result.success) {
        const updatedCartItems = cartItems.filter((item) => item.product._id !== id);
        updateCart(updatedCartItems);
      } else {
        console.error("Failed to remove product from cart:", result.message);
      }
    } else {
      const updatedCart = removeFromLocalCart(id);
      updateCart(updatedCart);
      const updatedCartProducts = updatedCart.map((item) => item.product);
      setProducts(updatedCartProducts);
    }
  };

  const updateCart = (updatedCartItems: typeof cartItems) => {
    setCartItems(updatedCartItems);
  };

  const handleEditQuantity = (id: string) => {
    setEditingItemId(id);
    const item = cartItems.find((item) => item.product._id === id);
    if (item) {
      setTempQuantity(item.quantity);
    }
  };

  const changeItemQuantity = (id: string, newQuantity: number) => {
    setTempQuantity(Math.max(1, newQuantity));
  };

  const handleApproveChange = async (id: string) => {
    const product = cartItems.find((item) => item.product._id === id);
    if (!product) return;

    if (user) {
      const updatedCartItems = cartItems.map((item) =>
        item.product._id === id ? { ...item, quantity: tempQuantity! } : item
      );
      updateCart(updatedCartItems);
      setEditingItemId(null);
      const result = await userService.updateCart(user, setUser, product.product, tempQuantity!, navigate);
      if (!result.success) {
        console.error("Failed to update cart on the server:", result.message);
      }
    } else {
      const updatedCart = updateLocalCartItemQuantity(id, tempQuantity!);
      updateCart(updatedCart);
      setEditingItemId(null);
    }
  };

  const renderItemActions = (item: IProductInCart) => (
    editingItemId === item?.product._id ? (
      <div className="quantity-controls">
        <button onClick={() => changeItemQuantity(item?.product._id, tempQuantity! - 1)}>-</button>
        <span>{tempQuantity}</span>
        <button onClick={() => changeItemQuantity(item?.product._id, tempQuantity! + 1)}>+</button>
        <button onClick={() => handleApproveChange(item?.product._id)}>APPROVE</button>
      </div>
    ) : (
      <div className="default-controls">
        <button onClick={() => handleEditQuantity(item?.product._id)} style={{ fontWeight: 700 }}>CHANGE</button>
        <button onClick={() => removeItemFromCart(item?.product._id)}>DELETE</button>
      </div>
    )
  );

  const validateStripe = useCallback(() => {
    if (!stripe || !elements) {
      return false;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error("CardElement is not available.");
      return false;
    }

    return true;
  }, [stripe, elements]);




  const confirmCardPayment = async (clientSecret: string): Promise<void> => {
    if (validateStripe()) {
      const cardElement = (elements as StripeElements).getElement(CardElement);


      const { error, paymentIntent } = await PaymentsApi.confirmStripePayment(
        stripe as Stripe,
        cardElement as StripeCardElement,
        clientSecret,
        {
          name: `${selectedAddress.first_name} ${selectedAddress.last_name}`,
        }
      );

      if (error) {
        console.error('Payment failed:', error.message);
        alert('Payment Failed');
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        globalService.handleUserCartAfterPayment(isAnonymousUser, user, setUser)
        setPaymentSuccess(true);
      }
    } else {
      console.error('Payment system is not ready. Please try again');
    }
  };

  const isFormValid = () => {
    // Check if selectedAddress exists.
    if (!selectedAddress) {
      return false;
    }

    const isShippingValid =
      userEmail &&
      selectedAddress.first_name &&
      selectedAddress.last_name &&
      selectedAddress.phone &&
      selectedAddress.street &&
      selectedAddress.streetNumber &&
      selectedAddress.postalCode &&
      selectedAddress.city &&
      selectedAddress.country;

    return isShippingValid;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSelectedAddress(prevAddress => ({
      ...prevAddress,
      [name]: value
    }));

    if (name === 'country') {
      setSelectedCountry(value);
    }
  };

  const creditCardInputValidation = (event: any) => {
    if (event.complete) {
      setIsCheckoutDisabled(false);
    } else {
      setIsCheckoutDisabled(true);
    }
  };

  const anonymousForm = () => {
    return (
      <>
        <form className={styles.addressForm}>
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="email">Your Email</label>
            <input
              id="email"
              className={`${styles.formInput} ${styles.emailInput}`}
              type="email"
              name="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="first_name">First Name</label>
            <input
              id="first_name"
              className={`${styles.formInput} ${styles.firstNameInput}`}
              type="text"
              name="first_name"
              value={selectedAddress?.first_name || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="last_name">Last Name</label>
            <input
              id="last_name"
              className={`${styles.formInput} ${styles.lastNameInput}`}
              type="text"
              name="last_name"
              value={selectedAddress?.last_name || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="phone">Phone Number</label>
            <input
              id="phone"
              className={`${styles.formInput} ${styles.phoneInput}`}
              type="text"
              name="phone"
              value={selectedAddress?.phone || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="street">Street</label>
            <input
              id="street"
              className={`${styles.formInput} ${styles.streetInput}`}
              type="text"
              name="street"
              value={selectedAddress?.street || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="streetNumber">Street Number</label>
            <input
              id="streetNumber"
              className={`${styles.formInput} ${styles.streetNumberInput}`}
              type="text"
              name="streetNumber"
              value={selectedAddress?.streetNumber || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="postalCode">Zip Code</label>
            <input
              id="postalCode"
              className={`${styles.formInput} ${styles.postalCodeInput}`}
              type="text"
              name="postalCode"
              value={selectedAddress?.postalCode || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="city">State</label>
            <input
              id="city"
              className={`${styles.formInput} ${styles.cityInput}`}
              type="text"
              name="city"
              value={selectedAddress?.city || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            {renderCountrySelect()}
          </div>
        </form>
      </>
    );
  };

  const calculatePrice = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    const { subtotal } = calculateCartSubtotal(cartItems, selectedShippingService);
    return subtotal.toFixed(2);
  }



  const calculateTaxes = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    let shippingTax = 0;
    const { productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
    if (selectedShippingService?.tax) {
      const taxRate = selectedShippingService?.tax / 100;
      const totalShippingCost = selectedShippingService?.price;
      const shippingPrice = totalShippingCost / (1 + taxRate);
      // free shipping
      if (parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >= selectedShippingService.freeshipping) {
        shippingTax = 0;
      } else {
        shippingTax = Number((totalShippingCost - shippingPrice).toFixed(2));
      }

      return (productTax + shippingTax).toFixed(2);
    }
    return productTax.toFixed(2);


  }

  const renderCountrySelect = () => {
    let handleChange;
    let address;

    if (isAnonymousUser) {
      handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(e);
      address = selectedAddress;
    } else {
      handleChange = handleCountryChangeForNewAddress;
      address = newAddress;
    }

    return (
      <div className={styles.formInputBox}>
        <label className={styles.formInputLabel} htmlFor="country">Country</label>
        <select
          id="country"
          name="country"
          value={address?.country || ''}
          onChange={handleChange}
          className={`${styles.formInput} ${styles.customSelect}`}
        >
          <option value="">Select Country</option>
          {deliveryCountries.map((countryObj, index) => (
            <option key={index} value={countryObj.country}>{countryObj.country}</option>
          ))}
        </select>
      </div>
    );
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Source Sans Pro, sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding: '10px 0',
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true,
  };

  useEffect(() => {
    if (!stripe || !elements) {
      setIsCheckoutDisabled(true);
      return;
    }
    setIsCheckoutDisabled(false);
  }, [stripe, elements]);

  return (
    <div className={styles.checkoutWithHeaderContainer}>
      <HomepageNavbar />
      {paymentSuccess ? (
        <div className={styles.completeOrderContainer}>
          <div className={styles.imageContainer} style={{ display: window.innerWidth < 768 ? 'none' : 'block' }}>
            <img src={valentineDaysBack} alt="valentineDaysBack" className={styles.valentineDaysBack} />
          </div>
          <div className={styles.textContainer}>
            <div className={`${styles.checkoutSuccessIconContainer}`}>
              <img src={profileSelectionIcon} alt="Checkout Success" className={styles.checkoutSuccessIcon} />
            </div>
            <p className={styles.completeOrderTitle}>YOUR ORDER IS COMPLETED</p>
            <p className={styles.completeOrderSubtitle}>Thank you for your purchase!</p>
            <div className={styles.backToShopButtonContainer}>
              <button className={styles.backToHomeButton} onClick={() => (window.location.href = '/shop')}>
                BACK TO SHOP
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.checkoutContainer}>
            <div className={styles.checkoutForm}>

              <div className={styles.checkoutPaymentContainer}>
                <h2 className={styles.title}>CHECKOUT & PAYMENT</h2>
                {
                  isAnonymousUser ?
                    <div className={styles.shippingSection}>
                      {anonymousForm()}
                    </div> :
                    <div className='addressbook_container'>
                      <div className='addressHeader'>
                        <div className={styles.sectionShippingBox}>
                          <h3 className={styles.sectionTitle}>SHIPPING TO</h3>
                          {!isAdding && !isEditing && (
                            <button className={styles.addAddressButton} onClick={handleAddNewAddress}>
                              + ADDRESS
                            </button>
                          )}
                        </div>
                      </div>
                      <div className='containerForm'>
                        {!isEditing && !isAdding && (
                          <div className={styles.addressCards}>
                            {shippingAddress.length === 0 ? (
                              <p className={styles.noItemsTitle}>NO ADDRESS YET</p>
                            ) : (
                              shippingAddress.map((address: ShippingAddress, index: number) => (
                                <div
                                  key={index}
                                  className={styles.addressCardWrapper}
                                  onClick={() => handleSelectAddress(index)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <AddressCard
                                    shippingAddress={address}
                                    isSelected={selectedAddressIndex === index}
                                    onEdit={() => handleEdit(index)}
                                  />
                                </div>
                              ))
                            )}
                          </div>
                        )}


                        {isAdding && (
                          <>
                            <div className={styles.formHeader}>
                              <h3 className={styles.formTitle}>ADD ADDRESS</h3>
                              <span
                                className={styles.backText}
                                onClick={() => setIsAdding(false)}
                                style={{ cursor: 'pointer' }}
                              >
                                &lt; Back
                              </span>
                            </div>
                            <form className={styles.addressForm} onSubmit={handleFormSubmit}>


                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="firstName">First Name</label>
                                <input
                                  id="firstName"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.first_name}
                                  onChange={(e) => setNewAddress({ ...newAddress, first_name: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="lastName">Last Name</label>
                                <input
                                  id="lastName"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.last_name}
                                  onChange={(e) => setNewAddress({ ...newAddress, last_name: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="phone">Phone number</label>
                                <input
                                  id="phone"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.phone}
                                  onChange={(e) => setNewAddress({ ...newAddress, phone: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="street">Street</label>
                                <input
                                  id="street"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.street}
                                  onChange={(e) => setNewAddress({ ...newAddress, street: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="streetNumber">Street Number</label>
                                <input
                                  id="streetNumber"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.streetNumber}
                                  onChange={(e) => setNewAddress({ ...newAddress, streetNumber: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="postalCode">Zip Code</label>
                                <input
                                  id="postalCode"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.postalCode}
                                  onChange={(e) => setNewAddress({ ...newAddress, postalCode: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="city">State</label>
                                <input
                                  id="city"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.city}
                                  onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
                                />
                              </div>

                              {renderCountrySelect()}
                              <div className="checkbox-wrapper-48">
                                <label className={`${styles.formInputLabel} ${styles.checkboxLabel}`}>
                                  <input
                                    type="checkbox"
                                    name="asDefault"
                                    checked={newAddress.asDefault}
                                    onChange={(e) => setNewAddress({ ...newAddress, asDefault: e.target.checked })}
                                  />
                                  <span style={{ marginLeft: '8px' }}>SAVE AS MY DEFAULT ADDRESS</span>
                                </label>
                              </div>
                              <div className={styles.formButtonContainer}>
                                <button className={styles.formButton} type="submit">SAVE CHANGES</button>
                              </div>
                            </form>
                          </>
                        )}
                        {isEditing && (
                          <>
                            <div className={styles.formHeader}>
                              <h3 className={styles.formTitle}>Edit Address</h3>
                              <span
                                className={styles.backText}
                                onClick={() => setIsEditing(false)}
                                style={{ cursor: 'pointer' }}
                              >
                                &lt; Back
                              </span>
                            </div>
                            <form className={styles.addressForm} onSubmit={handleFormSubmit}>


                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="firstName">First Name</label>
                                <input
                                  id="firstName"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.first_name}
                                  onChange={(e) => setNewAddress({ ...newAddress, first_name: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="lastName">Last Name</label>
                                <input
                                  id="lastName"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.last_name}
                                  onChange={(e) => setNewAddress({ ...newAddress, last_name: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="phone">Phone</label>
                                <input
                                  id="phone"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.phone}
                                  onChange={(e) => setNewAddress({ ...newAddress, phone: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="street">Street</label>
                                <input
                                  id="street"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.street}
                                  onChange={(e) => setNewAddress({ ...newAddress, street: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="streetNumber">Street Number</label>
                                <input
                                  id="streetNumber"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.streetNumber}
                                  onChange={(e) => setNewAddress({ ...newAddress, streetNumber: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="postalCode">Postal Code</label>
                                <input
                                  id="postalCode"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.postalCode}
                                  onChange={(e) => setNewAddress({ ...newAddress, postalCode: e.target.value })}
                                />
                              </div>

                              <div className={styles.formInputBox}>
                                <label className={styles.formInputLabel} htmlFor="city">City</label>
                                <input
                                  id="city"
                                  className={styles.formInput}
                                  type="text"
                                  value={newAddress.city}
                                  onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
                                />
                              </div>

                              {renderCountrySelect()}

                              <button className={styles.formButton} type="submit">SAVE CHANGES</button>
                            </form>
                          </>
                        )}

                      </div>
                    </div>
                }
              </div>

              <div className={styles.shippingSectionService}>
                <div className={styles.sectionShippingBox}>
                  <h3 className={styles.sectionTitle}>SHIPPING</h3>
                </div>
                <div className={styles.shippingOptions}>
                  {filteredShippingServices.length > 0 ? (
                    <ul className={styles.shippingList}>
                      {filteredShippingServices.map((service, index) => (
                        <li key={index} className={styles.shippingItem}>
                          <label
                            className={styles.labelContainer}
                            onClick={() => setSelectedShippingService(service)}
                            style={{ cursor: 'pointer' }}
                          >
                            {selectedShippingService === service ? (
                              <div className={styles.selectedIcon}>
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="12" cy="12" r="12" fill="black" />
                                  <path d="M9 12.5l2.5 2.5L15 10" stroke="white" strokeWidth="2" fill="none" />
                                </svg>
                              </div>
                            ) : (
                              <div className={styles.unselectedIcon}>
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="12" cy="12" r="12" fill="rgba(204, 185, 167, 0.5)" />
                                </svg>
                              </div>
                            )}
                            <div>
                              <div className={styles.priceServicePrice}>
                                <span className={styles.servicePrice}>€{service.price}</span> <span> — </span>
                                <span
                                  className={`${styles.serviceName} ${selectedShippingService === service ? styles.selectedServiceName : ''}`}
                                >
                                  {service.service}
                                </span>
                              </div>
                              <span className={styles.serviceDelivery}>Delivery between {service.timedelivery}</span>
                            </div>
                          </label>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className={styles.noItemsTitle}>NO ADDRESS SELECTED</p>
                  )}
                </div>


              </div>


              <div className={styles.paymentSection}>
                <div className={styles.sectionShippingBox}>
                  <h3 className={styles.sectionTitle}>PAYMENT METHOD</h3>
                </div>
                <div className={styles.paymentOptions}>
                  <label
                    className={styles.option}
                    onClick={() => handleSelectPayment('creditCard')}
                    style={{ cursor: 'pointer' }}
                  >
                    {selectedPayment === 'creditCard' && (
                      <div className={styles.selectedIcon}>
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" r="12" fill="black" />
                          <path d="M9 12.5l2.5 2.5L15 10" stroke="white" strokeWidth="2" fill="none" />
                        </svg>
                      </div>
                    )}
                    CREDIT/DEBIT CARD
                  </label>
                </div>
              </div>


              <div className={styles.paymentSection}>
                <div className={styles.sectionShippingBox}>
                  <h3 className={styles.sectionTitle}>SECURE PAYMENT</h3>
                </div>
                <div className={styles.cardElementContainer}>
                  {stripe && elements ? (
                    <CardElement
                      options={cardElementOptions}
                      onChange={creditCardInputValidation}
                    />
                  ) : (
                    <div className={styles.loadingPayment}>
                      Loading payment system...
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.orderSummary}>
              <div className={styles.sectionShippingBox}>
                <h3 className={styles.sectionTitle}>PACKAGE ({getTotalItems()})</h3>
              </div>
              <div className={styles.productListScroll}>

                {products.map((product, index) => (
                  <div key={index} className={styles.product}>
                    <img src={product.imgUrl} alt={product.title} className={styles.productImage} />
                    <div className={styles.productDetails}>
                      <h5>{product.brand}</h5>
                      <div className={styles.productDesc}>
                        {product ? (
                          <>
                            <p className={styles.productDescTitle}>{product.title}</p>
                            <p className={styles.productDescSize}>{product.quantity}ml</p>
                            {renderItemActions(cartItems[index])}
                          </>
                        ) : (
                          <p>Product not available</p>
                        )}
                      </div>
                    </div>
                    <p className={styles.productPrice}>
                      {cartItems[index]?.quantity && product?.price
                        ? `${cartItems[index].quantity} * €${Number(product.price).toFixed(2)}`
                        : ''}
                    </p>
                  </div>
                ))}
              </div>

              {cartItems && cartItems.length > 0 ? (
                <div className={styles.cartFooter}>
                  <div className={styles.totalSection}>
                    <div className={styles.sumupDetails}>
                      <div className={styles.totalRow}>
                        <span className={styles.subtotalDiv} style={{ fontSize: '14px' }}><span>SUBTOTAL</span><span> ({getTotalItems()})</span></span>
                        <span className={styles.subtotalDivPrice}>€{calculatePrice(cartItems, selectedShippingService)}</span>
                      </div>
                      <div className={styles.totalRow}>
                        <span>SHIPMENT </span>
                        <span className={styles.subtotalDivPrice}>
                          {selectedShippingService ? (
                            parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >= selectedShippingService.freeshipping ? (
                              <>
                                <span className={styles.strikethroughPrice}>€{selectedShippingService.price.toFixed(2)}</span>
                                <br />
                                <span>€0.00</span>
                              </>
                            ) : (
                              `€${selectedShipmentWithoutTax(selectedShippingService).toFixed(2)}`
                            )
                          ) : (
                            <span>€0.00</span>
                          )}
                        </span>
                      </div>


                      <div className={styles.totalRow}>
                        <span>TAXES</span>
                        <span className={styles.subtotalDivPrice}>€{calculateTaxes(cartItems, selectedShippingService)}</span>
                      </div>
                      <div className={styles.totalRow}>
                        <strong className={styles.totalPrice}>TOTAL</strong>
                        <strong className={styles.totalPrice}>
                          €{selectedShippingService &&
                            parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >= selectedShippingService.freeshipping
                            ? calculateTotalAmountIncludingTax(cartItems, selectedShippingService)
                            : calculateTotalAmountIncludingAllTax(cartItems, selectedShippingService)
                          }
                        </strong>
                      </div>
                    </div>
                    <button
                      className={`${styles['checkout-btn']} ${styles['desktop-checkout-btn']}`}
                      onClick={handleCheckout}
                      disabled={!isFormValid() || isCheckoutDisabled}
                    >
                      CONFIRM ORDER
                    </button>
                  </div>
                </div>
              ) : (
                <p>No Items in Cart</p>
              )}
            </div>
          </div>
          {/* <div className={styles.mobileCheckoutButton} style={{ display: window.innerWidth < 768 ? 'block' : 'none' }}>
            <button
              className={`${styles['checkout-btn']} ${styles['mobile-checkout-btn']}`}
              onClick={handleCheckout}
              disabled={!isFormValid() || isCheckoutDisabled}
            >
              CONFIRM ORDER
            </button>
          </div> */}
        </>
      )
      }
    </div >
  );
};

export default CheckoutPage;