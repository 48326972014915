import React, { useEffect, useState } from 'react';
import { StepIndicator } from 'components/counter/counter';
import { SignupNavbar } from '../../../components/navbars/SignupNavbar/SignupNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { useNavigate } from 'react-router-dom';
import '../style.scss';

export const AnalyzeSkin: React.FC = () => {
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingPercentage((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prev + 1;
            });
        }, 30);

        if (loadingPercentage === 100) {
            navigate('/shop');
        }

        return () => clearInterval(interval);
    }, [loadingPercentage, navigate]);

    return (
        <div className="headerBlockFormsSkin" style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
            <SignupNavbar />
            <div className="form-skin-profile-container">
                <div className="form-container-skin">
                    <StepIndicator currentStep={6} />
                    <h1 className="title">ANALYZE IN PROGRESS</h1>

                    <div className="loader-container">
                        <div className="loader">
                            <div className="percentage">{loadingPercentage}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
