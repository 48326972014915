export const setLocalStorageItem = (key: string, value: any) => {
    try {
        const serializedValue = typeof value === 'string' ? value : JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Error setting item to local storage:', error);
    }
};


export const getLocalStorageItem = (key: string) => {
    try {
        const serializedValue = localStorage.getItem(key);
        return serializedValue ? JSON.parse(serializedValue) : null;
    } catch (error) {
        console.error('Error getting item from local storage:', error);
        return null;
    }
};


export const clearLocalStorage = (): void => {
    localStorage.clear();
};